import axios from "axios";
import { ContactProp } from "../Models/Contact/ContactProp";

const baseurl = `${process.env.REACT_APP_API_URL}`;

export const getContactProps = async (id: string) => {
  try {
    const response = await axios.get(
      `${baseurl}/contacts/${id}/contact-properties`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const postContactProps = async (id: string, props: ContactProp) => {
  try {
    const response = await axios.post(
      `${baseurl}/contacts/${id}/contact-properties`,
      {
        name: props.name,
        value: props.value,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw Error("failed trying to create contact property");
  }
};

export const deleteContactProps = async (id: string) => {
  try {
    const response = await axios.delete(`${baseurl}/contact-properties/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("failed trying to delete contact property");
  }
};
