import {
  Accordion,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
} from "react-bootstrap";
import { Contact } from "../../../Models/Contact/Contact";
import { ContactProp } from "../../../Models/Contact/ContactProp";
import React, { ChangeEvent, useEffect, useState } from "react";
import "../Base.css";
import { Access } from "../../../../Login/Models/User/Access";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Customer } from "../../../Models/Customer/Customer";
import { EditContactView } from "./EditContactView";
import { AddContact } from "./AddContact";
import { Tooltip } from "@mui/material";

interface CustomerContactProps {
  access?: Access;
  customer?: Customer;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
}

export function CustomerContact({
  access,
  customer,
  setCustomer,
}: CustomerContactProps) {
  const [contact, setContact] = useState<Contact[]>([]);
  const [currentContact, setCurrentContact] = useState<Contact>();
  const [contactProps, setContactProps] = useState<ContactProp[]>([]);

  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const [filterInput, setFilterInput] = useState("");
  const [filteredContact, setFilteredContact] = useState<Contact[]>([]);
  const [expand, setExpand] = useState(true);
  const [accordionState, setAccordionState] = useState<{
    [key: string]: boolean;
  }>({});
  const [hideInactive, setHideInactive] = useState(true);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteIsChecked, setDeleteIsChecked] = useState(true);

  useEffect(() => {
    if (customer) {
      const getContact = () => {
        setContact(customer.relations.contacts.map((res) => res));
        const props = contact.map((res) => res.relations.contactProperties);
        setContactProps(props.flat());
      };

      getContact();
    }
  }, [customer]);

  useEffect(() => {
    const newAccordionState = {} as { [key: string]: boolean };
    contact.forEach((res) => {
      newAccordionState[res.id] = expand;
    });
    setAccordionState(newAccordionState);
  }, [expand, contact]);

  const sortContacts = (data: Contact) => {
    return data.relations.contactProperties.map((res) => (
      <Row key={`${res.id}`} className="accordion-row-contact">
        <Col className="accordion-propertyNames-contact">{res.name}</Col>
        <Col className="accordion-values-contact" style={{ textAlign: "end" }}>
          {res.value}
        </Col>
      </Row>
    ));
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filterTerm = e.target.value;
    setFilterInput(filterTerm);

    if (!filterTerm) {
      setFilteredContact([]);
    } else {
      const filteredItems = contact.filter((result) => {
        const contactMatch = result.name
          .toLowerCase()
          .includes(filterTerm.toLowerCase());

        return contactMatch;
      });
      if (!filteredItems.length) {
        const filteredProperties = contact.filter((result) => {
          const props = result.relations.contactProperties.filter((res) => {
            const foundProps = res.value.toLowerCase().includes(filterTerm);
            return foundProps;
          });
          if (props.length) {
            return props;
          }
        });
        setFilteredContact(filteredProperties);
      } else {
        setFilteredContact(filteredItems);
      }
    }
  };

  const handleExpand = () => {
    setExpand(!expand);

    const newAccordionState = {} as { [key: string]: boolean };
    contact.forEach((res) => {
      newAccordionState[res.id] = expand;
    });
    setAccordionState(newAccordionState);
  };

  const handleAccordionToggle = (itemId: string) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const addCurrentProperties = (currentContact: ContactProp[]) => {
    const newProperties = currentContact.map((prop) => ({}));

    const addProps = (prevProps: ContactProp[]) => [
      ...newProperties,
      ...prevProps,
    ];
  };

  const handleEditContact = (chosenContact: Contact) => {
    setCurrentContact(chosenContact);
    setEditModalShow(true);
  };

  const handleExitContactEdit = () => {
    setEditModalShow(false);
  };

  const EditContactModal = () => {
    return (
      <Modal
        show={editModalShow}
        onEscapeKeyDown={() => handleExitContactEdit()}
        onHide={() => handleExitContactEdit()}
        scrollable={true}
        centered
        className="editContact-modal"
      >
        <EditContactView
          allCustomers={allCustomers}
          contact={currentContact as Contact}
          contactProps={contactProps}
          setContactProps={setContactProps}
          setEditModalShow={setEditModalShow}
          setDeleteIsChecked={setDeleteIsChecked}
        />
      </Modal>
    );
  };

  const renderFilteredAccordionItems = (
    isMatch: boolean,
    isActive: boolean,
    res: Contact,
    access: Access
  ) => {
    return (
      isMatch &&
      isActive && (
        <Accordion
          key={res.id}
          defaultActiveKey={["0"]}
          activeKey={accordionState[res.id] ? ["0"] : []}
          onSelect={() => handleAccordionToggle(res.id)}
          className="contactContainers-customer-base mb-2"
        >
          <Accordion.Item eventKey="0">
            <div className="contactAccordionContainer-customer-contact">
              <Accordion.Header className="contactAccordion-customer-contact">
                {res.isActive ? (
                  <span
                    className="contactName-customer-contact"
                    style={{
                      textDecoration: !hideInactive ? "line-through" : "none",
                    }}
                  >
                    {res.name}
                  </span>
                ) : (
                  <span className="contactName-customer-contact">
                    {res.name}
                  </span>
                )}
              </Accordion.Header>
              {access.administrator === 1 ||
              access.function_customer_base_edit === 1 ? (
                <Tooltip title="Redigera kontakt">
                  <div className="accordionIcons-customer-contact">
                    <span
                      className="contactIcons-customer-contact"
                      onClick={() => handleEditContact(res)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <span></span>
              )}
            </div>
            <Accordion.Body>
              <Container className="contactBody-contact">
                {sortContacts(res)}
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    );
  };

  const renderAccordionItems = (res: Contact, access: Access) => (
    <Accordion
      key={res.id}
      defaultActiveKey={["0"]}
      activeKey={accordionState[res.id] ? ["0"] : []}
      onSelect={() => handleAccordionToggle(res.id)}
      className="contactContainers-customer-base mb-2"
    >
      <Accordion.Item eventKey="0">
        <div className="contactAccordionContainer-customer-contact">
          <Accordion.Header className="contactAccordion-customer-contact">
            {res.isActive ? (
              <span
                className="contactName-customer-contact"
                style={{
                  textDecoration: !hideInactive ? "line-through" : "none",
                }}
              >
                {res.name}
              </span>
            ) : (
              <span className="contactName-customer-contact">{res.name}</span>
            )}
          </Accordion.Header>
          {access.administrator === 1 ||
          access.function_customer_base_edit === 1 ? (
            <Tooltip title="Redigera kontakt">
              <div className="accordionIcons-customer-contact">
                <span
                  className="contactIcons-customer-contact"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditContact(res);
                  }}
                >
                  {<FontAwesomeIcon icon={faPen} />}
                </span>
              </div>
            </Tooltip>
          ) : (
            <span></span>
          )}
        </div>
        <Accordion.Body>
          <Container className="contactBody-contact">
            {sortContacts(res)}
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  const renderView = () => {
    if (
      access?.administrator === 1 ||
      access?.function_customer_base_view === 1
    ) {
      return (
        <Card.Body className="d-flex flex-column align-items-center">
          {filteredContact && filteredContact.length > 0 ? (
            filteredContact.map((res) => {
              const isMatch = filteredContact.includes(res);
              const isActive = !res.isActive || !hideInactive;
              return renderFilteredAccordionItems(
                isMatch,
                isActive,
                res,
                access
              );
            })
          ) : filterInput ? (
            <h4>Kontakt hittades ej</h4>
          ) : (
            contact.map(
              (res) =>
                (!res.isActive || !hideInactive) &&
                renderAccordionItems(res, access)
            )
          )}
          {currentContact && <EditContactModal />}
        </Card.Body>
      );
    } else return null;
  };

  return (
    <Card className="cardContainer" style={{ backgroundColor: "#f3f4f5" }}>
      <div className="checkboxContainer-contact sticky-top">
        <AddContact
          contact={currentContact}
          access={access}
          customer={customer}
          setCustomer={setCustomer}
        />
        <div
          className="d-flex flex-row ms-3"
          style={{ boxShadow: "0px 10px 4px -2px ##F8F5F3" }}
        >
          <Form.Control
            size="sm"
            type="input"
            placeholder="Filtrera"
            className="w-25 align-self-center"
            value={filterInput}
            onChange={handleFilterChange}
          />
          <Form.Check
            type="checkbox"
            label="Expandera alla"
            className="align-self-center ms-3"
            checked={expand}
            onChange={(e) => {
              e.preventDefault();
              handleExpand();
            }}
          />
          <Form.Check
            type="checkbox"
            label="Dölj inaktiva"
            className="align-self-center ms-3"
            checked={hideInactive}
            onChange={(e) => {
              e.preventDefault();
              setHideInactive(!hideInactive);
            }}
          />
        </div>
      </div>
      {renderView()}
    </Card>
  );
}
