import "../CaseInformation.css";
import { Button, Card, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Case } from "../../../Models/Case";
import { User } from "../../../../Users/Models/User";
import { getStorage } from "../../../../../GlobalServices/SessionStorageService";
import { getAllUsers } from "../../../../Users/Services/UserServices";
import { updateCase } from "../../../Services/CaseService";

interface informationProps {
  currentCase: Case;
  setCurrentCase: React.Dispatch<React.SetStateAction<Case>>;
}

export function CustomerInformation({
  currentCase,
  setCurrentCase,
}: informationProps) {
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [users, setUsers] = useState<User[] | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [editCase, setEditCase] = useState<Case>(currentCase);

  useEffect(() => {
    if (!users && getStorage("allUsers")) {
      setUsers(getStorage("allUsers"));
    } else if (users && getStorage("allUsers")) {
      return;
    } else {
      getUsers();
    }
  }, []);

  const getUsers = async () => {
    const data = await getAllUsers();
    setUsers(data);
  };

  const getDate = (value: Date | undefined) => {
    if (value) {
      const date = value.toString();
      return date?.split("T")[0];
    }
  };

  const getCreatedUser = () => {
    if (currentCase) {
      const user = users?.find((res) => res.id === currentCase.createdBy);
      return user?.name;
    } else return "";
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newDate = deadline.toLocaleDateString();
    setEditCase((prev) => ({
      ...prev,
      [name]:
        name === "deadline"
          ? newDate
          : value !== "deadline"
          ? Number(value)
          : value,
    }));
  };

  const updateInformation = async () => {
    const result = await updateCase(currentCase.id, editCase);
    setCurrentCase(result);
    setIsChecked(!isChecked);
  };

  const editSelected = !isChecked
    ? "formValue-case-info"
    : "formValue-case-info-active";
  return (
    <Card className="cardContainer-customerInformation">
      <div className="cardTitle-case-base sticky-top">
        <Card.Title className="cardContainerTitle">
          <h2 className="title-case-info">Kundinformation</h2>
          {!isChecked ? (
            <Button
              className="editButton-case-info"
              variant="outline-dark"
              onClick={() => setIsChecked(!isChecked)}
              disabled={!currentCase}
            >
              Ändra
            </Button>
          ) : (
            <>
              <Button
                className="editButton-case-info"
                variant="outline-danger"
                onClick={() => setIsChecked(!isChecked)}
              >
                Ångra
              </Button>
              <Button
                className="editButton-case-info"
                variant="outline-dark"
                onClick={() => updateInformation()}
              >
                Spara
              </Button>
            </>
          )}
        </Card.Title>
      </div>
      <Card.Body className="infoBody-customerInformation text-center">
        <Form>
          <Form.Group className="formGroup-case-info">
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Ärende skapat: </div>
              <div className="formName-case-info">
                {getDate(currentCase?.created)} av {getCreatedUser()}
              </div>
            </div>
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Ärende avslutat: </div>
              <div className="formValue-case-info">
                {currentCase?.finished === null
                  ? "Ej avslutat"
                  : getDate(currentCase?.finished)}
              </div>
            </div>
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Redovisad tid: </div>
              <div className="formValue-case-info">från tidsredovisningar</div>
            </div>
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Deadline: </div>
              <DatePicker
                className={
                  !isChecked
                    ? "datepicker-case-info"
                    : "datepicker-case-info-active"
                }
                name="deadline"
                selected={deadline}
                onChange={(e: Date) => setDeadline(e)}
                calendarStartDay={1}
                dateFormat="yy-MM-dd"
                isClearable
                disabled={!isChecked}
                onBlur={handleInput}
              />
            </div>
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Beräknad tid (h): </div>
              <Form.Control
                className={editSelected}
                name="timeFrame"
                onChange={handleInput}
                disabled={!isChecked}
                defaultValue={currentCase?.timeFrame}
              />
            </div>
            <div className="infoForm-case-info">
              <div className="formLabel-case-info">Pristak (kr): </div>
              <Form.Control
                className={editSelected}
                name="priceCeiling"
                onChange={handleInput}
                disabled={!isChecked}
                defaultValue={currentCase?.priceCeiling}
              />
            </div>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}
