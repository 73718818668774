import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Form } from "react-bootstrap";
import { Case, CaseOptions } from "../../Models/Case";

interface searchCaseProps {
  allCases: Case[];
  handleInputChange: (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => void;
}

export const SearchCase = ({
  allCases,
  handleInputChange,
}: searchCaseProps) => {
  return allCases ? (
    <Autocomplete
      className="searchContainer-customer-base"
      options={allCases?.map((res) => res)}
      getOptionLabel={(option: CaseOptions) =>
        `${option.caseNumber} ${option.title}`
      }
      renderInput={(params) => <TextField label="Sök ärende" {...params} />}
      size="small"
      autoHighlight={true}
      onInputChange={handleInputChange}
    />
  ) : (
    <Tooltip title="Sök upp en kund innan du fortsätter">
      <Form.Select className="placeholder-searchbar-case-base" disabled>
        {" "}
        <option>Sök ärende</option>
      </Form.Select>
    </Tooltip>
  );
};
