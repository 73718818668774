import axios from "axios";
import { Customer } from "../Models/Customer/Customer";

const baseurl = `${process.env.REACT_APP_API_URL}`;

export const getAllCustomers = async (): Promise<Customer[]> => {
  try {
    const response = await axios.get(`${baseurl}/customers`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed trying to get all customers");
  }
};

export const getCustomerById = async (id: string): Promise<Customer> => {
  try {
    const response = await axios.get(`${baseurl}/customers/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("error loading customer");
  }
};

export const updateCustomerByEdit = async (customer: Customer) => {
  const response = await axios.patch(`${baseurl}/customers/${customer.id}`, {
    invoiceMark: customer.invoiceMark,
    invoieCustomerId: customer.invoiceCustomerId,
    address2: customer.address2,
    address1: customer.address1,
    priceList: customer.priceList,
    postalAddress: customer.postalAddress,
    phone: customer.phone,
    registrationNumber: customer.registrationNumber,
    name: customer.name,
    agreementInfo: customer.agreementInfo,
    category: customer.category,
    responsibleUserId: customer.responsibleUserId,
  });
  return response.status;
};

export const postNewCustomer = async (name: string) => {
  const response = await axios.post(`${baseurl}/customers`, {
    name,
  });
  return response.data;
};
