import { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import "./Login.css";
import "../Root/Root.css";
import { useNavigate } from "react-router";
import { setStorage } from "../../GlobalServices/SessionStorageService";
import { loginUser } from "../Users/Services/UserServices";

export function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validAccount, setValidAccount] = useState(true);
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (validUser === true) {
      /* navigate("/autentisering"); */
      navigate("/intranat/ledning");
      window.location.reload();
    }
  }, [validUser]);

  const handlePasswordBox = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterLogin = (e: any) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    const response = await loginUser(username, password);
    if (response?.status === 204) {
      setValidUser(true);
      setStorage("username", username);
      setStorage("password", password);
      setStorage("validUser", true);
    } else {
      setValidUser(false);
      setStorage("validUser", false);
    }
  };
  return (
    <>
      <div className="background">
        <div className="backgroundContainer">
          <div className="background-logo-dizparc"> </div>
          <div className="cardContainer-login">
            <Card className="login-card">
              <div className="contentContainer-login">
                <Card.Title className="cardTitleContainer">
                  <h1 className="cardTitle">Logga in</h1>
                </Card.Title>
                <Card.Body className="cardInputFields">
                  <Form>
                    <Form.Group className="inputForm-login">
                      <FloatingLabel
                        className={
                          !validAccount
                            ? "floatingLabel-invalid"
                            : "floatingLabel"
                        }
                        controlId="floatingInput"
                        label="E-postadress"
                      >
                        <Form.Control
                          className={
                            !validAccount
                              ? "input-login-form"
                              : "input-login-form-invalid"
                          }
                          type="email"
                          placeholder="namn@exempel.se"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="inputForm-login">
                      <FloatingLabel
                        className={
                          !validAccount
                            ? "floatingLabel-invalid"
                            : "floatingLabel"
                        }
                        controlId="floatingInput-password"
                        label="Lösenord"
                      >
                        <Form.Control
                          className={
                            !validAccount
                              ? "input-login-form-invalid"
                              : "input-login-form"
                          }
                          type={!showPassword ? "password" : ""}
                          placeholder="******"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={(e) => handleEnterLogin(e)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <div className="checkBoxContainer">
                      <Form.Group className="checkboxContainer-login">
                        <Form.Check
                          className="checkBox-login"
                          onChange={handlePasswordBox}
                        />
                        <span className="checkBoxLabel">Visa lösenord</span>
                      </Form.Group>
                      <Form.Group className="checkboxContainer-login">
                        <Form.Check className="checkBox-login" />
                        <span className="checkBoxLabel">Kom ihåg mig</span>
                      </Form.Group>
                    </div>
                    <div className="buttonContainer">
                      <Button className="loginButton" onClick={handleLogin}>
                        <span>Logga in</span>
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </div>
              {!validAccount ? (
                <div className="isValidMessage">
                  <span>Fel E-postadress eller lösenord</span>
                </div>
              ) : (
                ""
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
