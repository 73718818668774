import axios from "axios";
import { Case } from "../Models/Case";

const baseurl = `${process.env.REACT_APP_API_URL}`;

export const createCase = async (id: string, errand: Case) => {
  try {
    const response = await axios.post(`${baseurl}/customers/${id}/cases`, {
      customerId: errand.customerId,
      contactId: errand.contactId,
      responsibleUserId: errand.responsibleUserId,
      description: errand.description,
      priority: errand.priority,
      title: errand.title,
      type: errand.type,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed trying to create case");
  }
};

export const getCaseById = async (id: string): Promise<Case> => {
  try {
    const response = await axios.get(`${baseurl}/cases/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed trying to fetch case");
  }
};

export const updateCase = async (id: string, newCase: Case) => {
  try {
    const response = await axios.patch(`${baseurl}/cases/${id}`, {
      customerId: newCase.customerId,
      contactId: newCase.contactId,
      responsibleUserId: newCase.responsibleUserId,
      priority: newCase.priority,
      title: newCase.title,
      description: newCase.description,
      internalStatus: newCase.internalStatus,
      deadline: newCase.deadline,
      timeFrame: newCase.timeFrame,
      priceCieling: newCase.priceCeiling,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const closeCase = async (id: string) => {
  try {
    const response = await axios.delete(`${baseurl}/cases/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
