import React, { useEffect, useState } from "react";
import { SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { SidebarLink } from "../../MainMenuSidebar/MainMenuSidebar";
import { getSubLinkAccess } from "../../../GlobalServices/accessLevelService";
import { User } from "../../Login/Models/User/User";
import userJSON from "../../Login/Models/userProfiles.json";
import { Access } from "../../Login/Models/User/Access";

interface IntranetMenuProps {
  isCollapsed: boolean;
}

const IntranetMenu: React.FC<IntranetMenuProps & { isActive: boolean }> = ({
  isCollapsed,
  isActive,
}) => {
  const [user, setUser] = useState<User>(userJSON);
  const [subLinks, setSubLinks] = useState<Access>();

  useEffect(() => {
    const accessData = getSubLinkAccess(user);
    accessData.map((res) => setSubLinks(res));
  }, [user]);

  return (
    <SubMenu
      title="Intranät"
      defaultOpen={isActive}
      label={
        <span>
          <FontAwesomeIcon
            className="MainSidebarMainCategoryIcon"
            icon={faGlobe}
          />
          {!isCollapsed ? "" : <div>Intranät</div>}
        </span>
      }
      className="MainSidebarMainCategoryText"
    >
      {subLinks?.administrator === 1 || subLinks?.subMenu_intranet === 1 ? (
        <SidebarLink to="/intranat/ledning" icon={faGlobe} label="Intranät" />
      ) : null}
    </SubMenu>
  );
};

export default IntranetMenu;
