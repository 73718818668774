import {
  getStorage,
  setStorage,
} from "../../../GlobalServices/SessionStorageService";
import { User } from "../Models/User";
import { getAllUsers, getCurrentUser } from "../Services/UserServices";

export const checkUser = (
  user: User | undefined,
  setUser: React.Dispatch<React.SetStateAction<User>>
) => {
  const fetchUser = async () => {
    const data = await getCurrentUser();
    setUser(data);
  };

  if (getStorage("user") && !user) {
    setUser(getStorage("user"));
  } else if (!getStorage("user") && user) {
    setStorage("user", user);
  } else if (!getStorage("user") && !user) {
    fetchUser();
  }
};

export const checkAfterAllUsers = async (
  users: User[],
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
) => {
  if (getStorage("allUsers") && !users.length) {
    setUsers(getStorage("allUsers"));
  } else if (!getStorage("allUsers") && users.length) {
    setStorage("allUsers", users);
  } else if (!getStorage("allUsers") && !users.length) {
    const data = await getAllUsers();
    setUsers(data);
    setStorage("allUsers", data);
  }
  if (getStorage("allUsers") !== undefined) {
    setUsers(getStorage("allUsers"));
  }
};
