import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navbar } from "./Components/Navbar/Navbar";
import { PageRoutes } from "./PageRoutes";
import { MainMenuSidebar } from "./Components/MainMenuSidebar/MainMenuSidebar";
import { Login } from "./Components/Login/Login";
import { Authentication } from "./Components/Authentication/Authentication";
import { getStorage } from "./GlobalServices/SessionStorageService";
import { AlertProvider } from "./Components/Root/Handlers/Alerts/Alerts"; // Se till att uppdatera sökvägen till din Alerts.tsx

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(getStorage("validUser"));
  }, []);

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  if (!isAuthenticated) {
    return <Login />;
  }
  if (window.location.pathname === "/autentisering") {
    return <Authentication />;
  } else {
    return (
      <QueryClientProvider client={client}>
        <AlertProvider>
          <Navbar />
          <MainMenuSidebar />
          <PageRoutes />
        </AlertProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
