import React, { FunctionComponent, useEffect, useState } from "react";
import { Sidebar, Menu, sidebarClasses } from "react-pro-sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import CustomerMenu from "../Customer/CustomerSidebarLink/CustomerMenu";
import IntranetMenu from "../Intranet/IntranetSidebarLink/IntranetMenu";

import "./MainMenuSidebar.css";
import { Button } from "react-bootstrap";
import ErrandMenu from "../Errand/ErrandSidebarLink/ErrandMenu";
import BillingMenu from "../MenuItems/BillingMenu";
import MyPageMenu from "../Profile/ProfileSidebarLink/ProfileMenu";
import userJSON from "../Login/Models/userProfiles.json";
import { User } from "../Login/Models/User/User";
import { Access } from "../Login/Models/User/Access";
import { setStorage } from "../../GlobalServices/SessionStorageService";

const styles = {
  primaryColor: process.env.REACT_APP_PRIMARYCOLOR,

  gradientBackground: `linear-gradient(to top, rgba(20,11,38,1) 0%, rgba(42,20,80,1) 60%, rgba(42,20,80,1) 97%, rgba(20,11,38,1) 100%)`,
};

export type SidebarLinkProps = {
  to: string;
  icon: IconDefinition;
  label: string;
};

export const SidebarLink: FunctionComponent<SidebarLinkProps> = ({
  to,
  icon,
  label,
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      isActive
        ? "MainSidebarSubCategoryText active-link"
        : "MainSidebarSubCategoryText"
    }
  >
    <FontAwesomeIcon className="MainSidebarSubCategoryIcon" icon={icon} />
    {label}
  </NavLink>
);

export const MainMenuSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [user, setUser] = useState<User>(userJSON);
  const [menuLink, setMenuLink] = useState<Access>();
  const location = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState<string>("");

  useEffect(() => {
    getChosenLinks();
    setStorage("sidebarCollapse", false);
  }, []);

  const containerStyles = {
    [`.${sidebarClasses.container}`]: {
      background: styles.gradientBackground,
      margin: 0,
      padding: 0,
      Width: "85px",
    },
  };

  const handleCollapsedState = () => {
    setIsCollapsed(!isCollapsed);

    document.body.classList.toggle("siderbarClosed"); // kolla öppen stängd
  };

  const buttonClass = `CollapseButtonSidebar ${
    isCollapsed
      ? "CollapseButtonSidebar-collapsed"
      : "CollapseButtonSidebar-expanded"
  }`;

  const getChosenLinks = () => {
    const menuItems = user.access.map((res) => res);
    menuItems.map((res) => setMenuLink(res));
  };

  // vilken submenu som man står och vilken som öppnas
  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setActiveSubMenu(path);
  }, [location]);

  return (
    <>
      <Sidebar
        className="sidebarBackground"
        rootStyles={containerStyles}
        collapsed={window.innerWidth < 200 ? isCollapsed : !isCollapsed}
        collapsedWidth="84px"
      >
        <div className="ButtonFixer">
          <Button
            onClick={handleCollapsedState}
            className={buttonClass}
            title="collapseButton"
          >
            <FontAwesomeIcon
              icon={!isCollapsed ? faAngleRight : faAngleLeft}
              className="black-icon"
            />
          </Button>
        </div>
        <div className="sidebarContainer">
          <Menu className="NavbarContainer">
            {menuLink?.administrator === 1 || menuLink?.menu_intranet === 1 ? (
              <IntranetMenu
                isCollapsed={isCollapsed}
                isActive={activeSubMenu === "intranat"}
              />
            ) : null}
            {menuLink?.administrator === 1 || menuLink?.menu_customer === 1 ? (
              <CustomerMenu
                isCollapsed={isCollapsed}
                isActive={activeSubMenu === "kund"}
              />
            ) : null}
            {menuLink?.administrator === 1 || menuLink?.menu_errand === 1 ? (
              <ErrandMenu
                isCollapsed={isCollapsed}
                isActive={activeSubMenu === "arende"}
              />
            ) : null}
            {menuLink?.administrator === 1 || menuLink?.menu_myPage === 1 ? (
              <MyPageMenu
                isCollapsed={isCollapsed}
                isActive={activeSubMenu === "profil"}
              />
            ) : null}
            {menuLink?.administrator === 1 || menuLink?.menu_billing === 1 ? (
              <BillingMenu
                isCollapsed={isCollapsed}
                isActive={activeSubMenu === "fakturering"}
              />
            ) : null}
          </Menu>
        </div>
      </Sidebar>
    </>
  );
};
