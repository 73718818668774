import React, { useEffect, useState } from "react";
import { SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faTasks,
  faCircleInfo,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { SidebarLink } from "../../MainMenuSidebar/MainMenuSidebar";
import { getSubLinkAccess } from "../../../GlobalServices/accessLevelService";
import { User } from "../../Login/Models/User/User";
import userJSON from "../../Login/Models/userProfiles.json";
import { Access } from "../../Login/Models/User/Access";

interface ErrandMenuProps {
  isCollapsed: boolean;
}

const CaseMenu: React.FC<ErrandMenuProps & { isActive: boolean }> = ({
  isCollapsed,
  isActive,
}) => {
  const [user, setUser] = useState<User>(userJSON);
  const [subLinks, setSubLinks] = useState<Access>();

  useEffect(() => {
    const accessData = getSubLinkAccess(user);
    accessData.map((res) => setSubLinks(res));
  }, [user]);

  return (
    <SubMenu
      title="Ärende"
      defaultOpen={isActive}
      label={
        <span>
          <FontAwesomeIcon
            className="MainSidebarMainCategoryIcon"
            icon={faBriefcase}
          />
          {!isCollapsed ? "" : " Ärende"}
        </span>
      }
      className="MainSidebarMainCategoryText"
    >
      {subLinks?.administrator === 1 || subLinks?.subMenu_errandBase === 1 ? (
        <SidebarLink to="/arende/grund" icon={faTasks} label="Grund" />
      ) : null}
      {subLinks?.administrator === 1 ||
      subLinks?.subMenu_errandInformation === 1 ? (
        <SidebarLink
          to="/arende/info"
          icon={faCircleInfo}
          label="Information"
        />
      ) : null}
      <SidebarLink
        to="/arende/arenden"
        icon={faClipboardList}
        label="Ärenden"
      />
    </SubMenu>
  );
};

export default CaseMenu;
