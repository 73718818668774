import React, { useEffect, useRef, useState } from "react";
import { CustomerData } from "./CustomerData";
import { CustomerContact } from "./CustomerContact/CustomerContact";
import {
  getCustomerById,
  postNewCustomer,
} from "../../Services/CustomerService";
import { Customer, defaultCustomer } from "../../Models/Customer/Customer";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { User } from "../../../Login/Models/User/User";
import userJSON from "../../../Login/Models/userProfiles.json";
import { Access } from "../../../Login/Models/User/Access";
import { getContentAccess } from "../../../../GlobalServices/accessLevelService";
import {
  faCheck,
  faCirclePlus,
  faList,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAllCustomers } from "../../Services/Handlers/CustomerHandlers/CustomerHandler";
import {
  removeStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import { SearchCustomer } from "../../Services/Handlers/SearchCustomer";
import { useAlert } from "../../../Root/Handlers/Alerts/Alerts";

export function Base() {
  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [searchInput, setSearchInput] = useState("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [newCustomerActive, setNewCustomerActive] = useState<boolean>(false);
  const [newCaseActive, setNewCaseActive] = useState<boolean>(false);
  const [newCustomer, setNewCustomer] = useState<Customer>(defaultCustomer);
  const [inputCustomer, setInputCustomer] = useState<Customer | null>(null);

  const [user, setUser] = useState<User>(userJSON);
  const [access, setAccess] = useState<Access>();

  const { showAlert } = useAlert();

  useEffect(() => {
    if (allCustomers.length) {
      setStorage("allCustomers", allCustomers);
    }
  }, [allCustomers]);

  useEffect(() => {
    const fetchAccess = async () => {
      const accessData = await getContentAccess(user);
      accessData.map((res) => setAccess(res));
    };

    fetchAccess();
  }, [user]);

  const isMounted = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    fetchAllCustomers(
      allCustomers,
      customer,
      setAllCustomers,
      setCustomer as React.Dispatch<React.SetStateAction<Customer>>
    );
  }, [allCustomers, customer]);

  //förhindrar att sidan laddas om om "enter" trycks fler ggr
  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    const fetchCustomerById = async () => {
      if (!searchInput) {
        return;
      }
      if (!isMounted.current) {
        try {
          const data = await getCustomerById(searchInput);
          setCustomer(data);
        } catch (error) {
          console.error("Failed trying to fetch customer", error);
        }
      } else {
        isMounted.current = true;
      }
    };

    fetchCustomerById();
  }, [searchInput]);

  useEffect(() => {
    if (customer) {
      setStorage("cases", customer.relations.cases);
    }
  }, [customer]);

  const handleInputChange = (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => {
    if (option.length >= 10) {
      const input = option.split(" ")[0];
      allCustomers.filter((match) =>
        match.customerNumber === input ? setSearchInput(match.id) : ""
      );
    } else {
      setSearchInput("");
    }
  };

  const createNewCustomer = async () => {
    if (!newCustomer) return;
    try {
      const data = await postNewCustomer(newCustomer.name);
      showAlert(`Kund: ${newCustomer.name} skapades`, "success");
      setAllCustomers((prev) => [...prev, data]);
      const customerData = await getCustomerById(data.id);
      setCustomer(customerData);
      setNewCustomerActive(false);
      removeStorage("currentCase");
    } catch (error) {
      showAlert(
        `Kund: Det gick ej att skapa kund: ${newCustomer.name}`,
        "danger"
      );
      console.error("Failed trying to post new customer", error);
    }
  };

  const addNameToCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="contentBody">
        <div className="customerBody">
          <div
            className={
              newCustomerActive
                ? "searchCustomer-newCustomer"
                : "searchCustomer"
            }
          >
            <Form ref={formRef}>
              <Form.Group
                className="searchCustomer-form"
                controlId="searchGroup"
              >
                <div className="searchAndTitle-customer-base">
                  <SearchCustomer
                    isChecked={isChecked}
                    allCustomers={allCustomers}
                    inputCustomer={inputCustomer}
                    setInputCustomer={setInputCustomer}
                    handleInputChange={handleInputChange}
                  />
                  <h3 className="customerTitle-customer-base">
                    {!customer
                      ? "Ingen kund vald"
                      : `${customer?.customerNumber} - ${customer?.name}`}
                  </h3>
                </div>
                <div className="titleIcons-customer-base">
                  <Tooltip title="Ny kund">
                    <Button
                      variant="outline-dark"
                      onClick={() => setNewCustomerActive(!newCustomerActive)}
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className="newCustomer-customer-base"
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Nytt ärende">
                    <div className="icon-button-header">
                      <Button
                        variant="outline-dark"
                        onClick={() => setNewCaseActive(!newCaseActive)}
                        disabled={!customer}
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="newErrand-customer-base"
                        />
                      </Button>
                    </div>
                  </Tooltip>
                  <span>|</span>
                  <Tooltip title="Ärendelista, kommer snart">
                    <div className="icon-button-header">
                      <Button
                        variant="outline-dark"
                        disabled={true /* !customer */}
                      >
                        <FontAwesomeIcon
                          icon={faList}
                          className="errandList-customer-base"
                        />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </Form.Group>
            </Form>
            {newCustomerActive ? (
              <div className="newCustomerContainer">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Ange kundnamn"
                  onChange={addNameToCustomer}
                />
                <Button
                  variant="outline-dark"
                  onClick={() => createNewCustomer()}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            ) : null}
          </div>
          <span className="contentContainer">
            <Row className="columnContainer">
              <CustomerData
                customer={customer}
                setIsChecked={setIsChecked}
                isChecked={isChecked}
                access={access}
                newCaseActive={newCaseActive}
                setNewCaseActive={setNewCaseActive}
              />
              <Col>
                <CustomerContact
                  customer={customer}
                  setCustomer={setCustomer}
                  access={access}
                />
              </Col>
            </Row>
          </span>
        </div>
      </div>
    </>
  );
}
