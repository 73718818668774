import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { useState, useEffect, ChangeEvent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { Customer } from "../../Models/Customer/Customer";
import {
  getStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import { useNavigate } from "react-router";
import "./Current.css";
import "../Base/Base.css";
import { Case, defaultCase } from "../../../Errand/Models/Case";
import { Access } from "../../../Login/Models/User/Access";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Contact } from "../../Models/Contact/Contact";
import { createCase } from "../../../Errand/Services/CaseService";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { caseType, priorityList } from "../Base/CustomerData";
import { useAlert } from "../../../Root/Handlers/Alerts/Alerts";
import { getCustomerById } from "../../Services/CustomerService";
import { User } from "../../../Users/Models/User";
import { checkAfterAllUsers } from "../../../Users/Handlers/UserHandlers";

interface CurrentProps {
  currentCustomer?: Customer;
  setCurrentCustomer: React.Dispatch<React.SetStateAction<Customer>>;
  access?: Access;
  newCaseActive: boolean;
  setNewCaseActive: React.Dispatch<React.SetStateAction<boolean>>;
}
interface contactOption {
  name: string;
}

export function CurrentErrand({
  currentCustomer,
  setCurrentCustomer,
  access,
  newCaseActive,
  setNewCaseActive,
}: CurrentProps) {
  const [customer, setCustomer] = useState<Customer>(
    currentCustomer as Customer
  );
  const [errandList, setErrandList] = useState<Case[]>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterInput, setFilterInput] = useState("");
  const [filteredActivity, setFilteredActivity] = useState<Case[]>([]);
  const [newCase, setNewCase] = useState<Case>(defaultCase);
  const [users, setUsers] = useState<User[]>([]);

  const navigate = useNavigate();

  const { showAlert } = useAlert();

  useEffect(() => {
    if (!currentCustomer && getStorage("customer")) {
      if (getStorage("customer") !== undefined)
        setCustomer(getStorage("customer"));
    } else if (
      (currentCustomer && !getStorage("customer")) ||
      (currentCustomer && getStorage("customer"))
    ) {
      setCustomer(currentCustomer);
      setStorage("customer", currentCustomer);
    }
  }, [currentCustomer]);

  useEffect(() => {
    checkAfterAllUsers(users, setUsers);
  }, []);

  useEffect(() => {
    setErrandList(customer?.relations.cases as Case[]);
  }, [currentCustomer, customer, errandList]);

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filterTerm = e.target.value;
    setFilterInput(filterTerm);

    if (!filterTerm) {
      setFilteredActivity([]);
    } else {
      const filteredItems = errandList?.filter((result) => {
        const contactMatchID = result.caseNumber
          .toLowerCase()
          .includes(filterTerm.toLowerCase());

        if (!contactMatchID) {
          const contactMatch = result.title
            .toLowerCase()
            .includes(filterTerm.toLowerCase());
          if (!contactMatchID && !contactMatch) {
            const createdByName = getCreatedByName(result.createdBy) as string;
            const contactMatchCreatedBy = createdByName
              .toLowerCase()
              .includes(filterTerm.toLowerCase());

            return contactMatchCreatedBy;
          }

          return contactMatch;
        }

        return contactMatchID;
      });
      setFilteredActivity(filteredItems as Case[]);
    }
  };

  const getCreatedByName = (id: string) => {
    if (users) {
      const user = users.find((res) => res.id === id);
      const firstname = user?.name.split(" ")[0];
      const lastname = user?.name.split(" ")[1];
      if (!lastname) {
        return firstname;
      } else {
        const first = firstname?.slice(0, 2);
        const last = lastname?.slice(0, 2);
        return first?.toUpperCase() + last.toUpperCase();
      }
    }
  };

  const handleClick = (errand: Case) => {
    setStorage("currentCase", errand);
    navigate("/arende/grund");
  };

  const createErrandForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCase((prevCase) => ({
      ...prevCase,
      [name]: value,
    }));
  };

  const createErrandSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, options } = e.target;
    const textValue = options[e.target.selectedIndex].text;
    setNewCase((prevCase) => ({
      ...prevCase,
      [name]: textValue,
    }));
  };

  const handleContactInput = (
    e: React.SyntheticEvent,
    value: Contact | null
  ) => {
    setNewCase((prevCase) => ({
      ...prevCase,
      contactId: `${value?.id}`,
    }));
  };

  const postCase = async () => {
    setNewCase((prev) => ({
      ...prev,
      customerId: `${customer?.id}`,
    }));
    try {
      const data = await createCase(customer?.id as string, newCase);
      const storageData = getStorage("cases") as Case[];
      storageData.push(data);
      setStorage("cases", storageData);
      const customerData = await getCustomerById(data.customerId);
      setCurrentCustomer(customerData);
      showAlert(`Ärende: ${data.title} skapades`, "success");
      setNewCaseActive(false);
    } catch (error) {
      showAlert(`Ärende: Det gick ej att skapa ärende`, "danger");
      console.error("Failed trying to post new customer", error);
    }
  };

  const renderNewCase = () => {
    const options = customer?.relations.contacts;

    return (
      <div className="newCase-container">
        <Form>
          <Form.Group className="newCase-formGroup">
            <div className="newCase-topContainer">
              <div className="newCase-leftContainer">
                <div className="newCase-formControl">
                  <Form.Label>Rubrik</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    size="sm"
                    onChange={createErrandForm}
                  />
                </div>
                <div className="newCase-formControl">
                  <Form.Label>Kontakt</Form.Label>
                  <Autocomplete
                    autoHighlight={true}
                    options={options as Contact[]}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(cont: contactOption) => `${cont.name}`}
                    onChange={(e, value: Contact | null) =>
                      handleContactInput(e, value)
                    }
                    popupIcon={
                      <FontAwesomeIcon
                        className="dropdownIcon-newCase"
                        icon={faChevronDown}
                      />
                    }
                  />
                  <Tooltip title="Skapa ny kontakt">
                    <FontAwesomeIcon
                      className="addContact-newCase"
                      icon={faPlus}
                      onClick={() => navigate("/kund/grund")}
                    />
                  </Tooltip>
                </div>
                <Form.Group className="newCase-selectboxes">
                  <div className="newCase-selectbox">
                    <Form.Label>Ärendetyp</Form.Label>
                    <Form.Select
                      className="caseType-selectbox"
                      name="type"
                      size="sm"
                      onChange={createErrandSelect}
                    >
                      <option hidden />
                      <option hidden />
                      {caseType.map((res, index) => (
                        <option key={index} value={res}>
                          {res}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="newCase-selectbox">
                    <Form.Label className="priority-label">Prio</Form.Label>
                    <Form.Select
                      className="priority-selectbox"
                      name="priority"
                      size="sm"
                      onChange={createErrandSelect}
                    >
                      <option hidden />
                      {priorityList.map((res, index) => (
                        <option key={index} value={res}>
                          {res}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </div>
              <div className="newCase-rightContainer">
                <div className="newCase-formControl">
                  <Form.Label>Beskrivning</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={3}
                    spellCheck={false}
                    onChange={createErrandForm}
                  />
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    );
  };

  const formatDate = (value: string) => {
    const fullDate = value.replace("T", " ");
    const date = fullDate.split(" ")[0];
    const time = fullDate.split(" ")[1];
    const timeList = time.split(":");
    return `${date} ${timeList[0]}:${timeList[1]}`;
  };

  const renderCaseLayout = () => {
    return (
      <Form>
        <Form.Group className="formGroup-top-customer-errand">
          <Form.Control
            size="sm"
            type="input"
            placeholder="Filtrera"
            className="inputBoxes-customer-errand"
            onChange={handleFilterChange}
          />
          <Form.Check
            type="checkbox"
            label="Visa avslutade ärenden mellan"
            className="checkBox-customer-errand"
          />
        </Form.Group>
        <Form.Group className="formGroup-bottom-customer-errand">
          <div className="datePicker-start-customer-errand">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date as Date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              calendarStartDay={1}
              dateFormat="yy-MM-dd"
              className="datePicker-input-errand"
            />
          </div>
          <span className="datePicker-divider-errand">-</span>
          <div className="datePicker-end-customer-errand">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date as Date)}
              selectsEnd
              endDate={endDate}
              minDate={startDate}
              calendarStartDay={1}
              dateFormat="yy-MM-dd"
              className="datePicker-input-errand"
            />
          </div>
        </Form.Group>
      </Form>
    );
  };

  return (
    <>
      <Card className="cardContainer-customer-errand">
        <div
          className={
            newCaseActive
              ? "cardContainerTitle-newCase-case sticky-top"
              : "cardContainerTitle-customerCase sticky-top"
          }
        >
          <Card.Title className="customerCase-title">
            <div className="newCase-title">
              <h2 className="caseTitle-current">
                {newCaseActive ? "Nytt ärende" : "Ärenden"}
              </h2>
              {newCaseActive ? (
                <div className="newCase-buttons-case">
                  <Button
                    variant="outline-danger"
                    onClick={() => setNewCaseActive(!newCaseActive)}
                  >
                    Avbryt
                  </Button>
                  <Button variant="outline-dark" onClick={postCase}>
                    Skapa
                  </Button>
                </div>
              ) : null}
            </div>
            {!newCaseActive ? renderCaseLayout() : null}
            {newCaseActive && renderNewCase()}
          </Card.Title>
        </div>
        <Card.Body className="cardBody-customer-errand">
          {filteredActivity && filteredActivity.length > 0 ? (
            errandList?.map((res) => {
              const isMatch = filteredActivity.includes(res);
              return (
                isMatch && (
                  <ListGroup variant="flush" className="case-customer-errand">
                    <ListGroup.Item
                      className="errandList-customer-errand"
                      action
                      onClick={() => handleClick(res)}
                    >
                      <div className="errandNumber-customer-errand">
                        {res.caseNumber}
                      </div>
                      <div className="title-customer-errand">{res.title}</div>
                      <div className="createdBy-customer-errand">
                        {getCreatedByName(res.createdBy)}
                      </div>
                      <div className="startDate-customer-errand">
                        {formatDate(res.created?.toString() as string)}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                )
              );
            })
          ) : filterInput ? (
            <h4>Ärende hittades ej</h4>
          ) : (
            errandList?.map((res) => (
              <ListGroup
                variant="flush"
                className="case-customer-errand"
                key={res.id}
              >
                <ListGroup.Item
                  className="errandList-customer-errand"
                  action
                  onClick={() => handleClick(res)}
                >
                  <div className="errandNumber-customer-errand">
                    {res.caseNumber}
                  </div>
                  <div className="title-customer-errand">{res.title}</div>
                  <div className="createdBy-customer-errand">
                    {getCreatedByName(res.createdBy)}
                  </div>
                  <div className="startDate-customer-errand">
                    {formatDate(res.created?.toString() as string)}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))
          )}
        </Card.Body>
      </Card>
    </>
  );
}
