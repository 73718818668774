import { Button, Card, Form, ToggleButtonGroup } from "react-bootstrap";
import { Customer } from "../../Models/Customer/Customer";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  getStorage,
  removeStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import { Access } from "../../../Login/Models/User/Access";
import { postUpdatedCustomer } from "../../Services/Handlers/CustomerHandlers/CustomerHandler";
import { Case, defaultCase } from "../../../Errand/Models/Case";
import { Contact } from "../../Models/Contact/Contact";
import { Autocomplete, TextField } from "@mui/material";
import { createCase } from "../../../Errand/Services/CaseService";
import { useNavigate } from "react-router";
import { useAlert } from "../../../Root/Handlers/Alerts/Alerts";
import { User, emptyUser } from "../../../Users/Models/User";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkAfterAllUsers } from "../../../Users/Handlers/UserHandlers";

interface CustomerProps {
  customer?: Customer;
  setCustomer?: Dispatch<SetStateAction<Customer>>;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  access?: Access;
  newCaseActive: boolean;
  setNewCaseActive: React.Dispatch<React.SetStateAction<boolean>>;
}

interface contactOption {
  name: string;
}

export const priorityList = [
  "Oprioriterad",
  "Turordnat",
  "Normalt",
  "Brådskande",
  "Akut",
  "SLA turordnat",
  "SLA normalt",
  "SLA brådskande",
  "SLA akut",
];

export const caseType = [
  "Applikationer",
  "Inköp",
  "Backup",
  "Internet",
  "Mobila enheter",
  "Utskrift",
  "Mail",
  "Klient",
  "Beställning arbete",
  "Kontoadministration",
  "server",
];

export function CustomerData({
  customer,
  setCustomer,
  isChecked,
  setIsChecked,
  access,
  newCaseActive,
  setNewCaseActive,
}: CustomerProps) {
  const [initialCustomer, setInitialCustomer] = useState<Customer>(
    customer as Customer
  );
  const [newCustomer, setNewCustomer] = useState<Customer>(
    customer as Customer
  );
  const [newCase, setNewCase] = useState<Case>(defaultCase);
  const [requiredContact, setRequiredContact] = useState<boolean>(true);
  const [users, setUsers] = useState<User[]>([]);
  const [defaultUser, setDefaultUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const getValueIfAllowed = (val: string) => {
    return access?.administrator === 1 ||
      access?.function_customer_base_view === 1
      ? val
      : "";
  };

  useEffect(() => {
    checkAfterAllUsers(users, setUsers);
  }, []);

  useEffect(() => {
    if (getStorage("customer") && !customer) {
      getCustomerFromStorage();
    } else if (!getStorage("customer") && customer) {
      fetchCustomerFromState();
    } else if (getStorage("customer") && customer) {
      getNewCustomerFromState();
    }
  }, [customer]);

  const fetchCustomerFromState = () => {
    setNewCustomer(customer as Customer);
    setInitialCustomer(customer as Customer);

    if (users) {
      const user = users.find((res) => res.id === customer?.responsibleUserId);
      setDefaultUser(user as User);
    }

    setStorage("customer", customer);
    setStorage("initialCustomer", customer);
  };

  const getCustomerFromStorage = () => {
    if (getStorage("editingActive") && getStorage("initialCustomer")) {
      const sessionActive = getStorage("editingActive");
      setIsChecked(sessionActive);
      const sessionInitCustomer = getStorage("initialCustomer");
      setInitialCustomer(sessionInitCustomer);
      if (getStorage("allUsers")) {
        const all = getStorage("allUsers") as User[];
        const user = all.find(
          (res) => res.id === initialCustomer.responsibleUserId
        );
        setDefaultUser(user as User);
      }
    }
    if (getStorage("customer") !== undefined) {
      const sessionCustomer = getStorage("customer");
      setNewCustomer(sessionCustomer);
    }
  };

  const getNewCustomerFromState = () => {
    removeStorage("customer");
    removeStorage("initialCustomer");
    setNewCustomer(customer as Customer);
    setInitialCustomer(customer as Customer);
    setStorage("customer", customer);
    setStorage("initialCustomer", customer);
    const all = getStorage("allUsers") as User[];
    setUsers(all);
    const user = all.find((res) => res.id === customer?.responsibleUserId);
    setDefaultUser(user as User);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
    setStorage("editingActive", !isChecked);
    if (isChecked && newCustomer) {
      postUpdatedCustomer(newCustomer);
    }
  };

  const handleReset = () => {
    setIsChecked(!isChecked);
    setStorage("editingActive", !isChecked);
    setNewCustomer(initialCustomer);
    if (newCustomer) {
      setStorage("customer", newCustomer);
    }
  };

  const handleResponsibleSelect = (
    e: React.SyntheticEvent,
    value: string,
    option: string
  ) => {
    const user = users.find((res) => res.name === value);
    setNewCustomer((prev) => ({
      ...prev,
      responsibleUserId: user?.id as string,
    }));
  };

  const updateCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (setNewCustomer) {
      setNewCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name]: value,
      }));
      setStorage("customer", { ...newCustomer, [name]: value });
    }
  };

  const informationForm = (name: string, value: string) => {
    if (name === "customerNumber") {
      return newCaseActive ? (
        <Form.Control
          name={name}
          size="sm"
          value={value !== null || undefined ? getValueIfAllowed(value) : ""}
          disabled={true}
        />
      ) : (
        <Form.Control
          name={name}
          value={value !== null || undefined ? getValueIfAllowed(value) : ""}
          disabled={true}
        />
      );
    } else {
      return newCaseActive ? (
        <Form.Control
          name={name}
          size="sm"
          value={value !== null || undefined ? getValueIfAllowed(value) : ""}
          onChange={updateCustomer}
          disabled={!isChecked}
        />
      ) : (
        <Form.Control
          name={name}
          value={value !== null || undefined ? getValueIfAllowed(value) : ""}
          onChange={updateCustomer}
          disabled={!isChecked}
        />
      );
    }
  };

  const renderView = () => {
    const newCaseInfoForm = newCaseActive ? "infoForm-newCase" : "infoForm";
    return (
      <Form id="dataForm" className="customerInformation-form">
        <Form.Group
          className="formgroup-container"
          controlId={
            !isChecked ? "formBasicCustomer" : "formBasicCustomer-active"
          }
        >
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Kundnummer</div>
            {informationForm(
              "customerNumber",
              !newCustomer?.customerNumber ? "" : newCustomer?.customerNumber
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Företagsnamn</div>
            {informationForm(
              "name",
              !newCustomer?.name ? "" : newCustomer?.name
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Orgnr</div>
            {informationForm(
              "registrationNumber",
              !newCustomer?.registrationNumber
                ? ""
                : newCustomer?.registrationNumber
            )}
          </div>
          <div>
            <div className="adressInfoForm">
              <div className="generalFormLabel">Adress</div>
              {informationForm(
                "address1",
                !newCustomer?.address1 ? "" : newCustomer.address1
              )}
            </div>
            <div className={newCaseInfoForm}>
              <div className="adressFiller"></div>
              {informationForm(
                "address2",
                !newCustomer?.address2 ? "" : newCustomer.address2
              )}
            </div>
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Postadress</div>
            {informationForm(
              "postalAddress",
              !newCustomer?.postalAddress ? "" : newCustomer.postalAddress
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">E-post (fakturor)</div>
            {informationForm("invoiceEmail", " ")}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Kundref. faktura</div>
            {informationForm(
              "invoiceMark",
              !newCustomer?.invoiceMark ? "" : newCustomer.invoiceMark
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Telefon</div>
            {informationForm(
              "phone",
              !newCustomer?.phone ? "" : newCustomer.phone
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Kundansvarig</div>
            <Autocomplete
              className={
                !isChecked
                  ? "newActivity-form-autocomplete"
                  : "newActivity-form-autocomplete-edit"
              }
              options={users as User[]}
              getOptionLabel={(option: contactOption) => option.name}
              renderInput={(params) => <TextField {...params} />}
              size="small"
              disabled={!isChecked}
              onChange={(e, val) => setDefaultUser(val)}
              value={defaultUser ? (defaultUser as User) : emptyUser}
              disableClearable
              popupIcon={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xs"
                  className="newActivity-dropdown-icon"
                />
              }
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onInputChange={handleResponsibleSelect}
            />
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Fakturakund</div>
            {informationForm(
              "name",
              !newCustomer?.name ? "" : newCustomer.name
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Kategori</div>
            {informationForm(
              "category",
              !newCustomer?.category ? "" : newCustomer.category
            )}
          </div>
          <div className={newCaseInfoForm}>
            <div className="generalFormLabel">Prislista</div>
            {informationForm(
              "priceList",
              !newCustomer?.priceList ? "" : newCustomer.priceList
            )}
          </div>
        </Form.Group>
        <Form.Group
          className="agreementInfo-customer-data"
          controlId="agreementInfo-textArea"
        >
          <Form.Control
            name="agreementInfo"
            as="textarea"
            rows={newCaseActive ? 20 : 29}
            spellCheck={false}
            disabled={!isChecked}
            className={!isChecked ? "contractText" : "contractText-Active"}
            onChange={updateCustomer}
            style={{ whiteSpace: "pre-wrap" }}
            value={
              newCustomer?.agreementInfo !== null || undefined
                ? getValueIfAllowed(newCustomer?.agreementInfo)
                : ""
            }
          />
        </Form.Group>
      </Form>
    );
  };

  const renderEditButtonCustomer = () => {
    if (
      access?.administrator === 1 ||
      access?.function_customer_base_edit === 1
    ) {
      return (
        <ToggleButtonGroup type="checkbox" className="ms-auto">
          {isChecked ? (
            <Button
              type="reset"
              variant="outline-danger"
              id="tbg-btn-1"
              value="Reset"
              onClick={handleReset}
              className="resetButton-customer-info"
            >
              Ångra
            </Button>
          ) : null}
          <Button
            variant="outline-dark"
            id="tbg-btn-1"
            value={1}
            onClick={handleToggle}
            className="editButton-customer-info"
            disabled={!customer ? true : false}
          >
            {isChecked ? "Spara" : "Ändra"}
          </Button>
        </ToggleButtonGroup>
      );
    } else return "";
  };

  const createErrandForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCase((prevCase) => ({
      ...prevCase,
      [name]: value,
    }));
  };

  const createErrandSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, options } = e.target;
    const textValue = options[e.target.selectedIndex].text;
    setNewCase((prevCase) => ({
      ...prevCase,
      [name]: textValue,
    }));
  };

  const handleContactInput = (e: SyntheticEvent, value: Contact | null) => {
    setNewCase((prevCase) => ({
      ...prevCase,
      contactId: `${value?.id}`,
    }));
  };

  const postCase = async () => {
    setNewCase((prev) => ({
      ...prev,
      customerId: `${customer?.id}`,
    }));
    if (!newCase.contactId) {
      setRequiredContact(false);
      return showAlert("Välj en kontakt", "danger");
    }
    const data = await createCase(customer?.id as string, newCase);
    const storageData = getStorage("cases") as Case[];
    storageData.push(data);
    setStorage("cases", storageData);
    setStorage("currentCase", data);
    navigate("/arende/grund");
  };
  const renderNewCase = () => {
    const options = customer?.relations.contacts;
    return (
      <div className="newCase-container">
        <Form>
          <Form.Group className="newCase-formGroup" controlId="newCase-form">
            <div className="newCase-topContainer">
              <div className="newCase-leftContainer">
                <div className="newCase-formControl">
                  <Form.Label>Rubrik</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    size="sm"
                    onChange={createErrandForm}
                  />
                </div>
                <div className="newCase-formControl">
                  <Form.Label>Kontakt</Form.Label>
                  <Autocomplete
                    key={0}
                    autoHighlight={true}
                    options={options as Contact[]}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(cont: contactOption) => `${cont.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option.customerId === value.customerId
                    }
                    className={
                      requiredContact ? "" : "requiredField-customer-data"
                    }
                    onChange={(e, value: Contact | null) =>
                      handleContactInput(e, value)
                    }
                  />
                </div>
                <Form.Group
                  className="newCase-selectboxes"
                  controlId="newCase-selectBoxes"
                >
                  <div className="newCase-selectbox">
                    <Form.Label>Ärendetyp</Form.Label>
                    <Form.Select
                      className="caseType-selectbox"
                      name="type"
                      id="type"
                      size="sm"
                      onChange={createErrandSelect}
                    >
                      <option hidden></option>
                      {caseType.map((res, index) => (
                        <option key={index} value={res}>
                          {res}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="newCase-selectbox">
                    <Form.Label className="priority-label">Prio</Form.Label>
                    <Form.Select
                      className="priority-selectbox"
                      name="priority"
                      id="prio"
                      size="sm"
                      onChange={createErrandSelect}
                    >
                      <option hidden></option>
                      {priorityList.map((res, index) => (
                        <option key={index} value={index}>
                          {res}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Form.Group>
              </div>
              <div className="newCase-rightContainer">
                <div className="newCase-formControl">
                  <Form.Label>Beskrivning</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={3}
                    spellCheck={false}
                    onChange={createErrandForm}
                  />
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    );
  };

  return (
    <Card className="cardContainer-information">
      <div
        className={
          newCaseActive
            ? "cardContainerTitle-newCase sticky-top"
            : "cardContainerTitle-customerInformation sticky-top"
        }
      >
        <Card.Title className="customerInformation-title">
          <div className="newCase-title">
            <h2 className="ps-3 align-self-center">
              {newCaseActive ? "Skapa ärende" : "Kundinformation"}
            </h2>
            {newCaseActive ? (
              <div className="newCase-buttons">
                <Button
                  variant="outline-danger"
                  onClick={() => setNewCaseActive(!newCaseActive)}
                >
                  Avbryt
                </Button>
                <Button variant="outline-dark" onClick={postCase}>
                  Skapa
                </Button>
              </div>
            ) : null}
          </div>
          {newCaseActive ? renderNewCase() : renderEditButtonCustomer()}
        </Card.Title>
      </div>
      <Card.Body className="infoBody">{renderView()}</Card.Body>
    </Card>
  );
}
