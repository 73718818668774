import { BaseInformation } from "./BaseInformation/BaseInformation";
import { Planning } from "./Planning/Planning";
import { TimeReport } from "./TimeReport/TimeReport";
import "./Case.css";
import { useState, useEffect, useRef } from "react";
import { getContentAccess } from "../../../../GlobalServices/accessLevelService";
import { Access } from "../../../Login/Models/User/Access";
import userJSON from "../../../Login/Models/userProfiles.json";
import { User as oldUsers } from "../../../Login/Models/User/User";
import {
  faCirclePlus,
  faEnvelope,
  faAddressBook,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Form, Button, Row, Popover, OverlayTrigger } from "react-bootstrap";
import { Case } from "../../Models/Case";
import {
  getStorage,
  removeStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import { getCaseById } from "../../Services/CaseService";
import { getAllUsers } from "../../../Users/Services/UserServices";
import { User } from "../../../Users/Models/User";
import { useNavigate } from "react-router";
import { SearchCase } from "../../Services/Handlers/SearchCase";
import { Customer } from "../../../Customer/Models/Customer/Customer";

export function CaseBase() {
  const [user, setUser] = useState<oldUsers>(userJSON);
  const [access, setAccess] = useState<Access>();
  const [allCases, setAllCases] = useState<Case[]>();
  const [currentCase, setCurrentCase] = useState<Case | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [newCaseActive, setNewCaseActive] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer>();

  const isMounted = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    const isCustomerSameAsCase = async () => {
      if (getStorage("customer") && !customer) {
        setCustomer(getStorage("customer"));
      }
      if (customer) {
        const isSame = customer.relations.cases.find(
          (res) => res.id === currentCase?.id
        );
        if (isSame) {
          setAllCases(customer.relations.cases);
        } else {
          const data = getStorage("allCustomers") as Customer[];
          const isMatch = data.find(
            (res) => res.id === currentCase?.customerId
          );

          if (isMatch?.relations.cases) {
            setAllCases(isMatch.relations.cases);
          }
        }
      }
    };
    isCustomerSameAsCase();
  }, []);

  useEffect(() => {
    if (!currentCase && getStorage("currentCase")) {
      setCurrentCase(getStorage("currentCase"));
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getAllUsers();
        setUsers(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAccess = async () => {
      const accessData = await getContentAccess(user);
      accessData.map((res) => setAccess(res));
    };

    fetchAccess();
  }, [user]);

  useEffect(() => {
    if (getStorage("cases")) {
      setAllCases(getStorage("cases"));
    } else removeStorage("cases");
  }, []);

  useEffect(() => {
    if (getStorage("cases")) setAllCases(getStorage("cases"));
  }, [newCaseActive]);

  useEffect(() => {
    const fetchCase = async () => {
      if (!searchInput) {
        return;
      }
      if (!isMounted.current) {
        try {
          const data = await getCaseById(searchInput);
          setCurrentCase(data);
          setStorage("currentCase", data);
        } catch (error) {
          console.error("Failed trying to fetch case", error);
        }
      } else {
        isMounted.current = true;
      }
    };

    fetchCase();
  }, [searchInput]);

  const handleInputChange = (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => {
    if (option.length >= 10) {
      const input = option.split(" ")[0];
      allCases?.filter((match) =>
        match.caseNumber === input ? setSearchInput(match.id) : ""
      );
    } else {
      setSearchInput("");
    }
  };

  const renderDeleteView = (
    <Popover className="popover-delete-view-case-base">
      <Popover.Body className="popver-delete-body-case-base">
        <div className="popover-delete-message-case-base">
          Vill du stänga ärendet <br />
          <strong>
            {currentCase?.caseNumber}-{currentCase?.title}
          </strong>
          ?
        </div>
        <div className="popover-buttonGroup-case-base">
          <Button variant="danger" onClick={() => document.body.click()}>
            Nej
          </Button>
          <Button variant="dark">Ja</Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="contentBody">
        <div className="content-case-base">
          <div className="searchCustomer">
            <Form ref={formRef}>
              <Form.Group
                className="searchCustomer-form"
                controlId="searchGroup"
              >
                <div className="searchAndTitle-customer-base">
                  <SearchCase
                    allCases={allCases as Case[]}
                    handleInputChange={handleInputChange}
                  />

                  <h3 className="customerTitle-customer-base">
                    {!currentCase
                      ? "Inget ärende valt"
                      : `${currentCase.caseNumber} - ${currentCase.title}`}
                  </h3>
                </div>
                <div className="titleIcons-case-base">
                  <div className="titleIcons-left-case-base">
                    <Tooltip title="Nytt ärende">
                      <div
                        className={
                          customer
                            ? "iconButton-case-base"
                            : "iconButton-case-base-disabled"
                        }
                        onClick={() => {
                          customer && setNewCaseActive(!newCaseActive);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="newCase-case-base"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="E-post, kommer snart">
                      <div className="iconButton-case-base-disabled">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                    </Tooltip>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={renderDeleteView}
                      rootClose
                    >
                      <Tooltip title="Avsluta ärende, kommer snart">
                        <div
                          className={
                            /* 
                            currentCase
                              ? "iconButton-case-base"
                              :  */ "iconButton-case-base-disabled"
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSquareXmark}
                            className="remove-case-base"
                          />
                        </div>
                      </Tooltip>
                    </OverlayTrigger>
                  </div>
                  <div className="titleIcons-left-case-base right-icon-case-base">
                    {" "}
                    <Tooltip title="Kund">
                      <div
                        className="iconButton-case-base customerButton-case-base"
                        onClick={() => navigate("/kund/grund")}
                        /* ska skickas till kunden ärendet är skrivet på */
                      >
                        <FontAwesomeIcon
                          icon={faAddressBook}
                          className="customerLink-case-base"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
          <span className="contentContainer-errand-base">
            <Row className="baseRow-errand-base">
              <BaseInformation
                access={access}
                currentCase={currentCase as Case}
                setCurrentCase={
                  setCurrentCase as React.Dispatch<React.SetStateAction<Case>>
                }
                users={users}
                newCaseActive={newCaseActive}
                setNewCaseActive={setNewCaseActive}
              />
            </Row>
            <Row className="secondRow-errand-base">
              <Planning
                access={access}
                currentCase={currentCase as Case}
                users={users}
              />
              <TimeReport />
            </Row>
          </span>
        </div>
      </div>
    </>
  );
}
