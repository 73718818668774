export function ErrandErrands() {
  return (
    <>
      <h1 className="d-flex flex-row justify-content-center mt-3">
        {" "}
        <br /> <br />
        Ärende
      </h1>
      <h3 className="d-flex flex-row justify-content-center mt-5 ">
        Kommer snart
      </h3>
    </>
  );
}
