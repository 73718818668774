import axios from "axios";
import { User } from "../Models/User";

const baseurl = `${process.env.REACT_APP_API_URL}`;

axios.defaults.withCredentials = true;

export const createUser = async (user: User) => {
  try {
    const response = await axios.post(`${baseurl}/users`, user);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed trying to create user");
  }
};

export const getAllUsers = async (): Promise<User[]> => {
  try {
    const response = await axios.get(`${baseurl}/users`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed trying to get users");
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axios.get(`${baseurl}/users/me`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed trying to get current user");
  }
};

export const loginUser = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${baseurl}/users/login`, {
      username: username,
      password: password,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = async () => {
  try {
    const response = await axios.post(`${baseurl}/users/logout`);
    return response.status;
  } catch (error) {
    console.error("Failed trying to logout user", error);
  }
};

export const checkTOTP = async (username: string) => {
  try {
    const response = await axios.post(`${baseurl}/users/totp/check`, {
      username: username,
    });
    return response;
  } catch (error) {}
};

export const getTOTPkey = async () => {
  try {
    const response = await axios.get(`${baseurl}/users/totp/key`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const registerTOTP = async (
  username: string,
  password: string,
  key: string,
  code: string
) => {
  try {
    const response = await axios.post(`${baseurl}/users/totp/register`, {
      username: username,
      password: password,
      key: key,
      code: code,
    });
    return response.status;
  } catch (error) {}
};

export const loginTOTP = async (username: string, code: string) => {
  const response = await axios.post(`${baseurl}/users/totp/login`, {
    withCredentials: true,
    username: username,
    code: code,
  });
  return response.status;
};
