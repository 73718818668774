import React, { useEffect, useState } from "react";
import { SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faAddressBook,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { SidebarLink } from "../../MainMenuSidebar/MainMenuSidebar";
import { getSubLinkAccess } from "../../../GlobalServices/accessLevelService";
import { User } from "../../Login/Models/User/User";
import userJSON from "../../Login/Models/userProfiles.json";
import { Access } from "../../Login/Models/User/Access";

interface CustomerMenuProps {
  isCollapsed: boolean;
}

const CustomerMenu: React.FC<CustomerMenuProps & { isActive: boolean }> = ({
  isCollapsed,
  isActive,
}) => {
  const [user, setUser] = useState<User>(userJSON);
  const [subLinks, setSubLinks] = useState<Access>();

  useEffect(() => {
    const accessData = getSubLinkAccess(user);
    accessData.map((res) => setSubLinks(res));
  }, [user]);

  return (
    <SubMenu
      title="Kund"
      defaultOpen={isActive}
      label={
        <span>
          <FontAwesomeIcon
            className="MainSidebarMainCategoryIcon"
            icon={faCircleUser}
          />
          {!isCollapsed ? "" : " Kund"}{" "}
        </span>
      }
      className="MainSidebarMainCategoryText"
    >
      {subLinks?.administrator === 1 || subLinks?.subMenu_customerBase === 1 ? (
        <SidebarLink to="/kund/grund" icon={faAddressBook} label="Grund" />
      ) : null}
      {subLinks?.administrator === 1 ||
      subLinks?.subMenu_customerCurrent === 1 ? (
        <SidebarLink
          to="/kund/aktuellt"
          icon={faPersonChalkboard}
          label="Aktuellt"
        />
      ) : null}
    </SubMenu>
  );
};

export default CustomerMenu;
