import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { Customer } from "../../../Models/Customer/Customer";
import {
  getAllCustomers,
  getCustomerById,
  updateCustomerByEdit,
} from "../../CustomerService";

export const postUpdatedCustomer = async (customer: Customer) => {
  const status = await updateCustomerByEdit(customer);
  setStorage("customer", customer);
  setStorage("initialCustomer", customer);

  if (status === 200) {
    getCustomerById(customer.id);
  }
};

export const fetchAllCustomers = async (
  allCustomers: Customer[],
  customer: Customer | undefined,
  setAllCustomers: React.Dispatch<React.SetStateAction<Customer[]>>,
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>
) => {
  if (!allCustomers.length && !getStorage("allCustomers")) {
    const data = await getAllCustomers();
    setAllCustomers(data);
    setStorage("allCustomers", data);
  } else if (!allCustomers.length && getStorage("allCustomers")) {
    setAllCustomers(getStorage("allCustomers"));
  }
  if (!customer && getStorage("customer")) {
    const storageCustomer = getStorage("customer");
    setCustomer(storageCustomer);
  } else if (customer && getStorage("customer")) {
    setStorage("customer", customer);
  } else if (customer && !getStorage("customer")) {
    setStorage("customer", customer);
  }
};
