import { Relations } from "../../Customer/Models/Customer/Relations";

export const emptyUser: User = {
  phone: "",
  name: "",
  isActive: false,
  username: "",
  password: "",
};

export type User = {
  createdBy?: string;
  phone: string;
  name: string;
  modifiedBy?: string;
  id?: string;
  relations?: Relations;
  isActive: boolean;
  deletedBy?: string;
  username: string;
  password: string;
};
