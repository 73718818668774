import axios from "axios";
import { Contact } from "../Models/Contact/Contact";

const baseurl = `${process.env.REACT_APP_API_URL}`;

export const getContactsById = async (id: string): Promise<Contact[]> => {
  try {
    const response = await axios.get(`${baseurl}/customers/${id}/contacts`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("error loading contact");
  }
};

export const getSingleContactById = async (id: string) => {
  try {
    const response = await axios.get(`${baseurl}/contacts/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createContact = async (
  id: string,
  name: string
): Promise<Contact> => {
  try {
    const response = await axios.post(`${baseurl}/customers/${id}/contacts`, {
      name: name,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("error trying to create contact");
  }
};

export const deleteContact = async (id: string) => {
  try {
    const response = await axios.delete(`${baseurl}/contacts/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("error trying to delete contact");
  }
};
