import { Routes, Route } from "react-router";
import { HomePage } from "./Components/HomePage/HomePage";
import { Base } from "./Components/Customer/Views/Base/Base";
import { Management } from "./Components/Intranet/Views/Management/Management";
import { Current } from "./Components/Customer/Views/Current/Current";
import { CaseBase } from "./Components/Errand/Views/Base/CaseBase";
import { CaseInformation } from "./Components/Errand/Views/Information/CaseInformation/CaseInformation";
import { ProfileCalendar } from "./Components/Profile/Views/Calendar/Calendar";
import { ErrandErrands } from "./Components/Errand/Views/Errand/Errand";
import { ProfileTodo } from "./Components/Profile/Views/Todo/Todo";
import { Login } from "./Components/Login/Login";
import { Authentication } from "./Components/Authentication/Authentication";
import { Information } from "./Components/Information/Information";
import { Invoice } from "./Components/Invoice/Invoice";
import { Settings } from "./Components/Settings/Settings";
import { ErrorPage } from "./Components/ErrorPages/Error404";

export function PageRoutes() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/autentisering" element={<Authentication />} />

        {/* Profile */}

        {/* Information */}
        <Route path="/profil/information" element={<Information />} />

        {/* invoice */}
        <Route path="/profil/inkorg" element={<Invoice />} />

        {/* Settings */}
        <Route path="installningar" element={<Settings />} />

        {/* ErrorPage */}

        <Route path="*" element={<ErrorPage />} />

        {/* OBS: Ta bort/lägg till route om man ska lägga till eller ta bort komponent */}

        <Route path="/" element={<HomePage />} />
        <Route path="/intranat/ledning" element={<Management />} />

        <Route path="/kund/grund" element={<Base />} />
        <Route path="/kund/aktuellt" element={<Current />} />

        <Route path="/arende/grund" element={<CaseBase />} />
        <Route path="/arende/info" element={<CaseInformation />} />
        <Route path="/arende/arenden" element={<ErrandErrands />} />

        {/* <Route path="/profil/todo" element={<ProfileTodo />} /> */}
        <Route path="/profil/kalender" element={<ProfileCalendar />} />
      </Routes>
    </>
  );
}
