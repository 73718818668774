import { Relations } from "../../Customer/Models/Customer/Relations";

export const defaultActivity: Activity = {
  description: "",
  isPrivate: false,
  title: "",
  isAllDay: false,
  isCustomerAgreement: false,
  isLocked: false,
  caseId: "",
  end: new Date(),
  start: new Date(),
  isBegun: false,
  isFinished: false,
  resourceTime: 0,
  userId: "",
  isAwaitingFeedback: false,
  resourceType: "",
  category: "",
};

export type Activity = {
  finishedBy?: string;
  begun?: Date;
  description?: string;
  begunBy?: string;
  isPrivate?: boolean;
  title: string;
  deletedBy?: string;
  isAllDay: boolean;
  isCustomerAgreement: boolean;
  isLocked: boolean;
  caseId?: string;
  customerId?: string;
  modified?: Date;
  modifiedBy?: string;
  end: Date | string;
  id?: string;
  created?: Date;
  start: Date | string;
  finished?: Date;
  isFinished: boolean;
  resourceTime: number;
  userId?: string;
  isBegun: boolean;
  deleted?: Date;
  createdBy?: string;
  relations?: Relations[];
  isAwaitingFeedback: boolean;
  category?: string;
  resourceType?: string;
};
