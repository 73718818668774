import { Relations } from "./Relations";

export interface customerOption {
  customerNumber: string;
  name: string;
}

export const defaultCustomer = {
  invoiceMark: "",
  invoiceCustomerId: "",
  address2: "",
  created: new Date(),
  address1: "",
  customerNumber: "",
  deletedBy: "",
  priceList: "",
  deleted: new Date(),
  postalAddress: "",
  createdBy: "",
  phone: "",
  registrationNumber: "",
  agreementInfo: "",
  name: "",
  modified: new Date(),
  modifiedBy: "",
  id: "",
  category: "",
  relations: {
    contacts: [],
    contactProperties: [],
    cases: [],
    activities: [],
  },
  responsibleUserId: "",
};

export type Customer = {
  invoiceMark: string;
  invoiceCustomerId: string;
  address2: string;
  created: Date;
  address1: string;
  customerNumber: string;
  deletedBy: string;
  priceList: string;
  deleted: Date;
  postalAddress: string;
  createdBy: string;
  phone: string;
  registrationNumber: string;
  agreementInfo: string;
  name: string;
  modified: Date;
  modifiedBy: string;
  id: string;
  category: string;
  relations: Relations;
  responsibleUserId: string;
};
