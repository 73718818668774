import React from "react";
import { Alert } from "react-bootstrap";
import "./Alerts.css";

interface AlertMessageProps {
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  children: React.ReactNode;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ variant, children }) => {
  return (
    <Alert variant={variant} className="alert-message">
      {children}
    </Alert>
  );
};

export default AlertMessage;
