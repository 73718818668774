import { Button, Form, Modal } from "react-bootstrap";
import { Activity } from "../../../Models/Activity";
import React, { useEffect, useState } from "react";
import { getStorage } from "../../../../../GlobalServices/SessionStorageService";
import { User, emptyUser } from "../../../../Users/Models/User";
import { Autocomplete, TextField } from "@mui/material";
import { contactOptions } from "../BaseInformation/BaseInformation";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "./editActivity.css";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import { resourceList } from "./newActivity";

interface EditActivityProps {
  activity: Activity;
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export function EditActivity({
  activity,
  showEditModal,
  setShowEditModal,
}: EditActivityProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [person, setPerson] = useState<User>();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [startTime, setStartTime] = useState<Value>("00:00");
  const [endDate, setEndDate] = useState(new Date(new Date().setSeconds(0, 0)));
  const [endTime, setEndTime] = useState<Value>("00:00");

  useEffect(() => {
    if (!users?.length) {
      if (getStorage("allUsers")) {
        setUsers(getStorage("allUsers"));
      }
    }
  }, [users]);

  useEffect(() => {
    if (activity) {
      setStartDate(activity.start as Date);
      setEndDate(activity.end as Date);
      const timeStart = activity.start as string;
      setStartTime(timeStart.split("T")[1]);
      const timeEnd = activity.end as string;
      setEndTime(timeEnd.split("T")[1]);
      if (users) {
        const selectedUser = users.find((res) => res.id === activity.userId);
        setPerson(selectedUser);
      }
    }
  }, [activity, users]);

  const getCreatedDate = () => {
    const creadedFullDate = activity.created?.toString();
    const createdDate = creadedFullDate?.split("T")[0];
    return createdDate as string;
  };

  const getCreatedByName = () => {
    if (activity) {
      if (getStorage("allUsers")) {
        const allUsers = getStorage("allUsers") as User[];
        const createdUser = allUsers.find(
          (res) => res.id === activity.createdBy
        );
        return createdUser?.name as string;
      }
    }
  };

  return (
    <Modal
      show={showEditModal}
      size="lg"
      centered
      className="modal-editActivity"
    >
      <Modal.Header
        className="header-editActivity"
        closeButton
        onClick={() => setShowEditModal(!showEditModal)}
      >
        <Modal.Title className="title-editActivity">
          Redigera aktivitet: <b>{activity.title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form-editActivity">
          <Form.Group className="formGroup-editActivity">
            <div className="editForm-editActivity">
              <Form.Label>Skapad</Form.Label>
              <Form.Control
                className="createdBy-text-editActivity"
                plaintext
                readOnly
                defaultValue={`${getCreatedDate()} av ${getCreatedByName()}`}
              />
            </div>
            <div className="editForm-editActivity">
              <Form.Label>Person</Form.Label>
              <Autocomplete
                className="newActivity-form-autocomplete autocomplete-editActivity"
                options={users as User[]}
                getOptionLabel={(option: contactOptions) => option.name}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                onChange={(e, val) => setPerson(val)}
                value={person ? (person as User) : emptyUser}
                disableClearable
                popupIcon={
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="xs"
                    className="newActivity-dropdown-icon"
                  />
                }
              />
            </div>
            <div className="editForm-editActivity">
              <Form.Label>Från</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(e: Date) => setStartDate(e)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showWeekNumbers
                calendarStartDay={1}
                dateFormat="yy-MM-dd"
              />
              <TimePicker
                disableClock
                hourPlaceholder="00"
                minutePlaceholder="00"
                format="HH:mm"
                locale="sv-SE"
                onChange={(e) => setStartTime(e)}
                value={startTime}
              />
            </div>
            <div className="editForm-editActivity">
              <Form.Label>Till</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={(e: Date) => setEndDate(e)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                showWeekNumbers
                calendarStartDay={1}
                dateFormat="yy-MM-dd"
              />
              <TimePicker
                disableClock
                hourPlaceholder="00"
                minutePlaceholder="00"
                format="HH:mm"
                locale="sv-SE"
                onChange={(e) => setEndTime(e)}
                value={endTime}
              />
            </div>
            <div className="editForm-editActivity">
              <Form.Label className="resource-text-editActivity">
                Resurs
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                step={0.5}
                name="resourceTime"
                className="recourceTime-value-editActivity"
                defaultValue={activity.resourceTime}
              />
              <Form.Select
                name="resourceType"
                className="recourceType-value-editActivity"
                defaultValue={activity.resourceType}
              >
                {resourceList.map((res, index) => (
                  <option key={index}>{res}</option>
                ))}
              </Form.Select>
            </div>
            <div className="editForm-editActivity">
              <Form.Label>Titel</Form.Label>
              <Form.Control
                className="title-value-editActivity"
                defaultValue={activity.title}
              />
            </div>
            <div className="editForm-editActivity">
              <Form.Label className="description-text-editActivity">
                Beskrivning
              </Form.Label>
              <Form.Control
                className="description-value-editActivity"
                as="textarea"
                rows={12}
                defaultValue={activity.description}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => setShowEditModal(!showEditModal)}
        >
          Spara
        </Button>
        <Button
          variant="outline-dark"
          onClick={() => setShowEditModal(!showEditModal)}
        >
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
