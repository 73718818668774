import { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import "./Authentication.css";
import "../Root/Root.css";
import {
  getStorage,
  removeStorage,
} from "../../GlobalServices/SessionStorageService";
import {
  checkTOTP,
  getTOTPkey,
  loginTOTP,
  registerTOTP,
} from "../Users/Services/UserServices";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router";

export function Authentication() {
  const [activeContent, setActiveContent] = useState("totp");
  const [username, setUsername] = useState(getStorage("username"));
  const [password, setPassword] = useState(getStorage("password"));
  const [isRegistered, setIsRegistered] = useState<boolean>();
  const [showKey, setShowKey] = useState(false);
  const [totpKey, setTotpKey] = useState("");
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const checkTotpStatus = async () => {
      const response = await checkTOTP(username);
      if (response?.status === 200) {
        setIsRegistered(true);
      } else {
        setIsRegistered(false);
        const response = await getTOTPkey();
        setTotpKey(response);
      }
    };
    checkTotpStatus();
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleRegistration();
    }
  };

  const handleKeyPressLogin = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleRegistration = async () => {
    const response = await registerTOTP(username, password, totpKey, code);
    if (response === 204) {
      navigate("/intranat/ledning");
      removeStorage("password");
      window.location.reload();
    } else window.alert("fan också");
  };

  const handleLogin = async () => {
    const response = await loginTOTP(username, code);
    if (response === 204) {
      removeStorage("password");
      removeStorage("username");
      navigate("/intranat/ledning");
      window.location.reload();
    } else {
      window.alert("du fick inte logga in ;)");
    }
  };

  const checkIfRegistered = () => {
    if (isRegistered) {
      return (
        <div className="registration-registration-auth">
          <div className="authBox-registration-auth">
            <div className="authCodeBox-registration-auth">
              <div className="authCodeTextBox-registered-auth">
                <div className="authCodeText-auth">
                  Ange koden från autentiseringsappen
                </div>
              </div>
              <Form className="authCodeForm-auth">
                <Form.Control
                  type="text"
                  name="1"
                  className="authCodeInputForm-auth"
                  placeholder="XXX-XXX"
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={(e) => handleKeyPressLogin(e)}
                />
              </Form>
            </div>
            <div className="authFormButtons-registered-auth">
              <Button
                variant="danger"
                className="buttonCancel-auth"
                onClick={() => {
                  navigate("/login");
                  window.location.reload();
                  removeStorage("validUser");
                }}
              >
                Avbryt
              </Button>
              <Button
                variant="primary"
                className="buttonSubmit-auth"
                onClick={handleLogin}
              >
                Skicka
              </Button>
            </div>
          </div>
          <div className="forgotCode-auth">
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                window.alert("Tråkigt kompis");
              }}
            >
              Jag har tappat bort min nyckel
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="registration-auth">
          <div className="authBox-auth">
            <div className="authQr-auth">
              <QRCodeSVG
                value={`otpauth://totp/Dizparc?secret=${totpKey}&issuer=Navet&algorithm=SHA1&digits=6&period=30`}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"Q"}
                includeMargin={false}
              />
            </div>
            <div className="authCodeBox-auth">
              <div className="authCodeTextBox-auth">
                <div className="authCodeText-auth">
                  Ange koden från autentiseringsappen
                </div>
                <Button
                  size="sm"
                  className="showKeyButton-auth"
                  onClick={() => setShowKey(!showKey)}
                >
                  Visa kod
                </Button>
              </div>
              {showKey ? (
                <div className="showKey-auth">
                  <Form>
                    <Form.Control
                      size="sm"
                      disabled
                      value={totpKey}
                      className="keyinput-auth"
                    />
                  </Form>
                </div>
              ) : null}
              <Form className="authCodeForm-auth">
                <Form.Control
                  type="text"
                  name="1"
                  className="authCodeInputForm-auth"
                  placeholder="XXX-XXX"
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                />
              </Form>
            </div>
            <div className="authFormButtons-auth">
              <Button variant="danger" className="buttonCancel-auth">
                Ångra
              </Button>
              <Button
                variant="primary"
                className="buttonSubmit-auth"
                onClick={handleRegistration}
              >
                Skicka
              </Button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="background-auth">
        <div className="backgroundContainer-auth">
          <div className="background-logo-dizparc-auth"> </div>
          <div className="cardContainer-login-auth">
            <Card className="login-card-auth">
              <Card.Title className="cardTitleContainer-auth">
                <h2 className="cardTitle-auth">Autentisering</h2>
              </Card.Title>

              <div className="contentContainer-choise-auth">
                <Card.Body className="cardInputFields-auth">
                  <div className="buttonContainer-auth">
                    {/* <button
                      className="authButton-auth"
                      onClick={() => setActiveContent("bankid")}
                    >
                      Mobilt BankID
                    </button> */}
                    <button
                      className="authButton-auth"
                      onClick={() => setActiveContent("totp")}
                    >
                      Standard
                    </button>
                    {/* <button
                      className="authButton-auth"
                      onClick={() => setActiveContent("google")}
                    >
                      Google Signin
                    </button> */}
                  </div>

                  <div className="content-section-auth">
                    {activeContent === "default" && (
                      <div>Välj ett av sätten ovanför för att autentisera</div>
                    )}
                    {/* {activeContent === "bankid" && (
                      <div>Content for Mobilt BankID</div>
                    )} */}
                    {activeContent === "totp" && (
                      <div className="totpContent-auth">
                        <p
                          className={
                            isRegistered
                              ? "totpTitle-registration-auth"
                              : "totpTitle-auth"
                          }
                        >
                          {isRegistered
                            ? "Logga in med din autetinseringsapp"
                            : "Skanna QR koden nedanför med din autentiseringsapp"}
                        </p>
                        {checkIfRegistered()}
                      </div>
                    )}
                    {/* {activeContent === "google" && (
                      <div>Content for Google Signin</div>
                    )} */}
                  </div>
                </Card.Body>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
