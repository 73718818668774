import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import AlertMessage from "../Alerts/AlertMessage"; // Var alertmeddelanden kommer ifrån

type AlertVariant =
  | "success"
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

interface AlertContextType {
  showAlert: (message: string, variant?: AlertVariant) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert måste användas inom en AlertProvider");
  }
  return context;
};

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<{
    message: string;
    variant: AlertVariant;
  } | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const showAlert = (message: string, variant: AlertVariant = "success") => {
    setAlert({ message, variant });
    setIsVisible(true);

    const duration =
      variant === "danger" || variant === "warning" ? 5000 : 3000;

    setTimeout(() => {
      setIsVisible(false);
    }, duration);
  };

  // Rensa alert efter fadeat(krånglar lite så kanske värt att kolla på sen)
  useEffect(() => {
    if (!isVisible && alert !== null) {
      const timeoutId = setTimeout(() => {
        setAlert(null);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, alert]);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert &&
        ReactDOM.createPortal(
          <div className={`alert-transition ${isVisible ? "alert-show" : ""}`}>
            <AlertMessage variant={alert.variant}>{alert.message}</AlertMessage>
          </div>,
          document.body
        )}
    </AlertContext.Provider>
  );
};
