import {
  faCirclePlus,
  faEnvelope,
  faSquareXmark,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import {
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
} from "react-bootstrap";
import { Case } from "../../../Models/Case";
import { useState, useRef, useEffect } from "react";
import {
  getStorage,
  removeStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { getCaseById } from "../../../Services/CaseService";
import { useNavigate } from "react-router";
import "../CaseInformation.css";
import { FileHandler } from "../Filehandler/FileHandler";
import { CustomerInformation } from "./CustomerInformation";
import { SearchCase } from "../../../Services/Handlers/SearchCase";

export function CaseInformation() {
  const [allCases, setAllCases] = useState<Case[]>([]);
  const [currentCase, setCurrentCase] = useState<Case>();
  const [searchInput, setSearchInput] = useState("");
  const [newCaseActive, setNewCaseActive] = useState<boolean>(false);

  const isMounted = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    if (!currentCase && getStorage("currentCase")) {
      setCurrentCase(getStorage("currentCase"));
    } else if (currentCase && getStorage("currentCase")) {
      setStorage("currentCase", currentCase);
    } else if (!currentCase && !getStorage("currentCase")) {
    }
  }, [currentCase]);

  useEffect(() => {
    if (getStorage("cases") !== undefined || null) {
      setAllCases(getStorage("cases"));
    } else removeStorage("cases");
  }, []);

  useEffect(() => {
    const fetchCase = async () => {
      if (!searchInput) {
        return;
      }
      if (!isMounted.current) {
        try {
          const data = await getCaseById(searchInput);
          setCurrentCase(data);
          setStorage("currentCase", data);
        } catch (error) {
          console.error("Failed trying to fetch case", error);
        }
      } else {
        isMounted.current = true;
      }
    };

    fetchCase();
  }, [searchInput]);

  const handleInputChange = (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => {
    if (option.length >= 10) {
      const input = option.split(" ")[0];
      allCases.filter((match) =>
        match.caseNumber === input ? setSearchInput(match.id) : ""
      );
    } else {
      setSearchInput("");
    }
  };

  const renderDeleteView = (
    <Popover className="popover-delete-view-case-base">
      <Popover.Body className="popver-delete-body-case-base">
        <div className="popover-delete-message-case-base">
          Vill du stänga ärendet <br />
          <strong>
            {currentCase?.caseNumber}-{currentCase?.title}
          </strong>
          ?
        </div>
        <div className="popover-buttonGroup-case-base">
          <Button variant="danger" onClick={() => document.body.click()}>
            Nej
          </Button>
          <Button variant="dark">Ja</Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="contentBody">
        <div className="content-case-information">
          <div className="searchCustomer">
            <Form ref={formRef}>
              <Form.Group
                className="searchCustomer-form"
                controlId="searchGroup"
              >
                <div className="searchAndTitle-customer-base">
                  <SearchCase
                    allCases={allCases}
                    handleInputChange={handleInputChange}
                  />
                  <h3 className="customerTitle-customer-base">
                    {!currentCase
                      ? "Inget ärende valt"
                      : `${currentCase?.caseNumber} - ${currentCase?.title}`}
                  </h3>
                </div>
                <div className="titleIcons-case-base">
                  <div className="titleIcons-left-case-base">
                    <Tooltip title="Nytt ärende">
                      <div
                        className={
                          currentCase
                            ? "iconButton-case-base"
                            : "iconButton-case-base-disabled"
                        }
                        onClick={() => setNewCaseActive(!newCaseActive)}
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="newCase-case-base"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="E-post, kommer snart">
                      <div className="iconButton-case-base-disabled">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                    </Tooltip>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={renderDeleteView}
                      rootClose
                    >
                      <Tooltip title="Avsluta ärende, kommer snart">
                        <div
                          className={
                            /* 
                            currentCase
                              ? "iconButton-case-base"
                              :  */ "iconButton-case-base-disabled"
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSquareXmark}
                            className="remove-case-base"
                          />
                        </div>
                      </Tooltip>
                    </OverlayTrigger>
                  </div>
                  <div className="titleIcons-left-case-base right-icon-case-base">
                    {" "}
                    <Tooltip title="Kund">
                      <div
                        className="iconButton-case-base customerButton-case-base"
                        onClick={() => navigate("/kund/grund")}
                        /* ska skickas till kunden ärendet är skrivet på */
                      >
                        <FontAwesomeIcon
                          icon={faAddressBook}
                          className="customerLink-case-base"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
          <span className="contentContainer-case-information">
            <Row className="infoRow-case-information">
              <CustomerInformation
                currentCase={currentCase as Case}
                setCurrentCase={
                  setCurrentCase as React.Dispatch<React.SetStateAction<Case>>
                }
              />
              <Col className="filehandler-column">
                <FileHandler />
              </Col>
            </Row>
          </span>
        </div>
      </div>
    </>
  );
}
