import "../Base.css";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Tooltip } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { Access } from "../../../../Login/Models/User/Access";
import { ContactProp } from "../../../Models/Contact/ContactProp";
import { Contact } from "../../../Models/Contact/Contact";
import { createContact } from "../../../Services/ContactService";
import { Customer } from "../../../Models/Customer/Customer";
import { postContactProps } from "../../../Services/ContactPropService";
import { useAlert } from "../../../../Root/Handlers/Alerts/Alerts";
import { setStorage } from "../../../../../GlobalServices/SessionStorageService";
import { getCustomerById } from "../../../Services/CustomerService";

export const propertyValueList = [
  "Telefon arbete",
  "Telefon hem",
  "Mobil",
  "E-post",
  "Växel",
  "Kontaktväg",
  "Office",
  "Hemsida",
  "Ort",
  "Titel",
  "Användarnamn",
  "Operativsystem",
  "Datorvana",
  "Fax",
  "IP adress",
  "Lösenord",
];

interface AddContactProps {
  access?: Access;
  contact?: Contact;
  customer?: Customer;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
}

export function AddContact({ access, customer, setCustomer }: AddContactProps) {
  const [contactName, setContactName] = useState("");
  const [newContactProps, setNewContactProps] = useState<ContactProp[]>([
    { name: "", value: "" },
  ]);
  const [contactProps, setContactProps] = useState<ContactProp[]>([]);
  const [newContactActive, setNewContactActive] = useState(false);
  const [newContact, setNewContact] = useState<Contact>();

  const { showAlert } = useAlert();

  const handleNewContact = () => {
    setNewContactActive(!newContactActive);
  };

  const closeNewContact = () => {
    setNewContactActive(!newContactActive);
  };

  const handleNewContactProp = (
    e: SyntheticEvent,
    val: string | null,
    reason: string,
    index: number
  ) => {
    if (
      (val !== "" && reason === "blur") ||
      (val !== "" && reason === "selectOption")
    ) {
      setNewContactProps((prev) => [
        ...prev.slice(0, index),
        {
          ...prev[index],
          name: val as string,
        },
        ...prev.slice(index + 1),
      ]);
    }
  };

  const handleNewInputContactProp = (
    e: SyntheticEvent,
    reason: string,
    index: number
  ) => {
    if (reason === "blur" && (e.target as HTMLInputElement).value !== "") {
      const val = (e.target as HTMLInputElement).value;

      setNewContactProps((prev) => [
        ...prev.slice(0, index),
        {
          ...prev[index],
          name: val,
        },
        ...prev.slice(index + 1),
      ]);
    }
  };

  const handleClearPropertyList = (
    e: SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (reason === "clear") {
    }
  };

  const addProperty = () => {
    setNewContactProps((prev) => [
      ...prev,
      {
        name: "",
        value: "",
      },
    ]);
  };

  const removeProperty = (index: number) => {
    setNewContactProps((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleInputChange = (value: string, index: number) => {
    setNewContactProps((prev) => [
      ...prev.slice(0, index),
      {
        ...prev[index],
        value: value,
      },
      ...prev.slice(index + 1),
    ]);
  };

  const postContact = async () => {
    try {
      if ((customer as Customer).id) {
        const data = await createContact(customer?.id as string, contactName);
        showAlert(`${data.name} skapades`, "success");

        const postedProps = await Promise.all(
          newContactProps.map(async (res) => {
            const props = await postContactProps(data.id, res);
            return props;
          })
        );
        setContactProps(postedProps);

        data.relations.contactProperties = postedProps;
        setNewContact(data);
        const customerData = await getCustomerById(data.customerId);
        setCustomer(customerData);
        setStorage("customer", customerData);
      }
      closeNewContact();
    } catch {
      showAlert("Kontakt kunde ej skapas", "danger");
    }
  };

  const rendercontactProps = () => {
    return newContactActive ? (
      <div className="newContact-form-container">
        <Form className="newContact-form">
          <Form.Group className="newContact-formGroup">
            <Form.Label>Namn</Form.Label>
            <Form.Control
              name="contactName"
              onChange={(e) => setContactName(e.target.value)}
            />
            {newContactProps.map((res, index) => (
              <div
                key={`${index}-div`}
                className="newContact-properties-container"
              >
                <Autocomplete
                  className="newContact-properties"
                  key={`${index}-complete`}
                  options={propertyValueList}
                  value={res.name}
                  renderInput={(params) => (
                    <TextField label="Egenskap" {...params} />
                  )}
                  size="small"
                  freeSolo
                  onChange={(e, val, reason) =>
                    handleNewContactProp(e, val, reason, index)
                  }
                  onClose={(e, val) => handleNewInputContactProp(e, val, index)}
                  onInputChange={(e, val, reason) =>
                    handleClearPropertyList(e, val, reason)
                  }
                />
                <Form.Control
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  value={res.value}
                  key={`${index}-form`}
                />
                <Tooltip key={`${index}-tooltip`} title="Ta bort egenskap">
                  <Button
                    onClick={() => removeProperty(index)}
                    key={`${index}-button`}
                  >
                    <FontAwesomeIcon
                      key={`${index}-icon`}
                      icon={faXmark}
                      size="lg"
                    />
                  </Button>
                </Tooltip>
              </div>
            ))}

            <Tooltip title="Lägg till egenskap">
              <Button
                variant="outline-dark"
                className="newContact-addProperty"
                onClick={addProperty}
              >
                <FontAwesomeIcon key={customer?.customerNumber} icon={faPlus} />
              </Button>
            </Tooltip>
          </Form.Group>
        </Form>
      </div>
    ) : (
      ""
    );
  };

  return (
    <Card.Title
      className={
        !newContactActive
          ? "cardContainerTitle"
          : "cardContainerTitle-newContact"
      }
    >
      <div className="titleContainer-contact">
        {!newContactActive ? (
          <h2 className="contactTitle">Kontakter</h2>
        ) : (
          <h2 className="contactTitle-newContact">Ny kontakt</h2>
        )}

        {access?.administrator === 1 ||
        access?.function_customer_base_create === 1 ? (
          !newContactActive ? (
            <Tooltip title="Ny kontakt">
              <div className="addContactButton-contact">
                <Button
                  className="addContactButton"
                  variant="outline-dark"
                  onClick={handleNewContact}
                  disabled={!customer}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="stäng">
                <Button
                  className="addContactButton-active"
                  variant="outline-dark"
                  onClick={closeNewContact}
                >
                  <FontAwesomeIcon icon={faXmark} size="lg" />
                </Button>
              </Tooltip>
              <Button
                variant="outline-dark"
                className="addContactSaveButton"
                onClick={postContact}
              >
                Spara
              </Button>
            </>
          )
        ) : null}
      </div>
      {rendercontactProps()}
    </Card.Title>
  );
}
