import axios from "axios";
import { Activity } from "../Models/Activity";

const baseurl = `${process.env.REACT_APP_API_URL}`;

export const createActivity = async (activity: Activity) => {
  const response = await axios.post(`${baseurl}/activities`, activity);
  return response.data;
};

export const getAllCaseActivities = async (id: string): Promise<Activity[]> => {
  try {
    const response = await axios.get(`${baseurl}/cases/${id}/activities`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed trying to get activities");
  }
};

export const patchActivity = async (activity: Activity, id: string) => {
  try {
    const response = await axios.patch(`${baseurl}/activities/${id}`, activity);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed trying to update activity");
  }
};
