import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  Contact,
  emptyContact,
} from "../../../../Customer/Models/Contact/Contact";
import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { Customer } from "../../../../Customer/Models/Customer/Customer";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Case, defaultCase } from "../../../Models/Case";
import {
  caseType,
  priorityList,
} from "../../../../Customer/Views/Base/CustomerData";

interface contactOption {
  name: string;
}

interface newCaseProps {
  newCase: Case;
  setNewCase: React.Dispatch<React.SetStateAction<Case>>;
  requiredContact: boolean;
  setRequiredContact: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NewCase({
  newCase,
  setNewCase,
  requiredContact,
  setRequiredContact,
}: newCaseProps) {
  const [customer, setCustomer] = useState<Customer>();
  const [defaultContact, setDefaultContact] = useState<Contact | null>(null);
  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {
    if (!customer && getStorage("customer")) {
      setCustomer(getStorage("customer"));
    } else if (customer && !getStorage("customer")) {
      setStorage("customer", customer);
    } else if (!customer && getStorage("customer")) {
    }
    if (customer) {
      setContacts(customer.relations.contacts);
    }
  }, [customer]);

  useEffect(() => {
    setRequiredContact(true);
    setNewCase(defaultCase);
  }, []);

  const handleInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setNewCase((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactInput = (value: Contact) => {
    setDefaultContact(value);
    setNewCase((prev) => ({
      ...prev,
      contactId: value.id,
    }));
  };

  return (
    <Form className="formBody-baseInfo">
      <Form.Group className="formgroup-container-baseInfo-left">
        <div className="infoForm-baseInfo-left">
          <div className="generalFormLabel-baseInfo">Rubrik</div>
          <Form.Control
            name="title"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInput(e)
            }
          />
        </div>
        <div className="infoForm-baseInfo-left">
          <div className="generalFormLabel-baseInfo">Kontakt</div>
          <Autocomplete
            className={`newActivity-form-autocomplete ${
              requiredContact ? "" : "requiredField-case-baseInfo"
            }`}
            value={defaultContact ? (defaultContact as Contact) : emptyContact}
            options={contacts}
            getOptionLabel={(option: contactOption) => option.name}
            renderInput={(param) => <TextField {...param} />}
            disableClearable
            popupIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size="xs"
                className="newActivity-dropdown-icon"
              />
            }
            onChange={(e, val) => handleContactInput(val)}
          />
        </div>
        <div className="infoForm-baseInfo-left">
          <div className="generalFormLabel-baseInfo">Ärendetyp</div>
          <Form.Select
            name="type"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleInput(e)
            }
          >
            <option hidden />
            {caseType.map((res, index) => (
              <option key={index} value={res}>
                {res}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="infoForm-baseInfo-left">
          <div className="generalFormLabel-baseInfo">Prio</div>
          <Form.Select
            name="priority"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleInput(e)
            }
          >
            <option hidden />
            {priorityList.map((res, index) => (
              <option key={index} value={res}>
                {res}
              </option>
            ))}
          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group className="formgroup-container-baseInfo-right">
        <div className="infoForm-baseInfo-right-newCase">
          <div className="generalFormLabel-baseInfo">Beskrivning</div>
          <Form.Control
            as="textarea"
            name="description"
            className="textarea-newCase"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInput(e)
            }
          />
        </div>
      </Form.Group>
    </Form>
  );
}
