import { User } from "../Components/Login/Models/User/User";

export const getSubLinkAccess = (key: User) => {
  const accessData = key.access.map((res) => res);
  return accessData;
};

export const getContentAccess = async (key: User) => {
  const accessData = await Promise.all(key.access.map((res) => res));
  const data = accessData.map((res) => res);
  return data;
};
