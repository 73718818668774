import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField } from "@mui/material";
import { Form, Button } from "react-bootstrap";
import { Case } from "../../../Models/Case";
import { createActivity } from "../../../Services/ActivityService";
import React, { SyntheticEvent, forwardRef, useEffect, useState } from "react";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import { User } from "../../../../Users/Models/User";
import { Activity, defaultActivity } from "../../../Models/Activity";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { getAllUsers } from "../../../../Users/Services/UserServices";
import { useAlert } from "../../../../Root/Handlers/Alerts/Alerts";
import { categoryOptions } from "../../../../Profile/Views/Calendar/Calendar";

interface caseOption {
  caseNumber: string;
  title: string;
}

interface userOption {
  name: string;
}

export const resourceList = [
  "Övrigt",
  "Avtal",
  "Debiterbar",
  "Debiterbar preliminär",
  "Ledig",
  "Säljtid",
  "Support",
];

interface newActivityProps {
  newActivityActive: boolean;
  setnewActivityActive: React.Dispatch<React.SetStateAction<boolean>>;
  currentCase: Case;
  users: User[];
  allActivities: Activity[];
}

export function NewActivity({
  newActivityActive,
  setnewActivityActive,
  currentCase,
  users,
  allActivities,
}: newActivityProps) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [startTime, setStartTime] = useState<Value>("00:00");
  const [endDate, setEndDate] = useState(new Date(new Date().setSeconds(0, 0)));
  const [endTime, setEndTime] = useState<Value>("00:00");
  const [timeDiff, setTimeDiff] = useState<number>(0);
  const [newActivity, setnewActivity] = useState<Activity>(defaultActivity);
  const [allUsers, setAllUsers] = useState<User[]>(users);
  const [isAllDay, setIsAllDay] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const { showAlert } = useAlert();

  useEffect(() => {
    setIsRequired(false);
  }, []);

  useEffect(() => {
    const fetchAllUsers = async () => {
      const response = await getAllUsers();
      setAllUsers(response);
    };
    if (users.length <= 0 && getStorage("allUsers")) {
      setAllUsers(getStorage("allUsers"));
    } else if (users.length > 0 && !getStorage("allUsers")) {
      setAllUsers(users);
    } else if (users.length <= 0 && !getStorage("allUsers")) {
      fetchAllUsers();
    }
    if (!users.length) {
      const fetchUsers = async () => {
        try {
          const data = await getAllUsers();
          setAllUsers(data);
          setStorage("allUsers", data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchUsers();
    }
  }, []);

  const setCombinedStartDate = (e: SyntheticEvent) => {
    if (isAllDay) {
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(startDate.getDate());
      startDate.setHours(startDate.getHours());
    } else {
      if (startTime && startDate) {
        const hours = Number(startTime.split(":")[0]);
        const minutes = Number(startTime.split(":")[1]);
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(0);
      } else {
        setStartTime("00:00");
        setStartDate(new Date());
      }
    }
    const dateTosend = startDate.toLocaleString();
    setnewActivity((prev) => ({
      ...prev,
      start: dateTosend.replace(" ", "T"),
    }));
  };

  const setCombinedEndDate = () => {
    if (isAllDay) {
      endDate.setHours(0, 0, 0, 0);
      endDate.setDate(startDate.getDate() + 1);
      endDate.setHours(endDate.getHours());
    } else {
      if (endTime && endDate) {
        const hours = Number(endTime.split(":")[0]);
        const minutes = Number(endTime.split(":")[1]);
        endDate.setHours(hours);
        endDate.setMinutes(minutes);
        endDate.setSeconds(0);
      } else {
        setEndTime("00:00");
        setEndDate(new Date());
      }
    }
    const dateToSend = endDate.toLocaleString();
    setnewActivity((prev) => ({
      ...prev,
      end: dateToSend.replace(" ", "T"),
    }));
  };

  const handlePersonSelect = (
    e: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    const person = allUsers.find((res) => res.name === value);

    setnewActivity((prev) => ({
      ...prev,
      userId: person?.id as string,
      caseId: currentCase.id,
    }));
  };

  const handleInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setnewActivity((prev) => ({
      ...prev,
      [name]: name === "resourceTime" ? Number(value) : value,
    }));
    if (name === "resourceTime") {
      setTimeDiff(Number(value));
    }
  };

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setnewActivity((prev) => ({
      ...prev,
      [name]: e.target.checked,
    }));
  };

  const postActivity = async () => {
    if (!newActivity.userId) {
      setIsRequired(true);
      return showAlert("Välj en Person", "danger");
    }
    const data = await createActivity(newActivity);
    if (!allActivities) {
      allActivities = [];
      allActivities.push(data);
    } else {
      allActivities.push(data);
    }

    setStorage("activities", allActivities);
    showAlert(`${data.title} skapades`, "success");
    setnewActivityActive(false);
  };

  const handleStartDateChange = (e: Date) => {
    if (!e) {
      e = new Date();
    }
    setStartDate(e);
  };

  const handleEndDateChange = (e: Date) => {
    if (!e) {
      e = new Date();
    }
    setEndDate(e);
  };

  const renderNewActivity = () => {
    return (
      <>
        <Form className="newActivity-form">
          <Form.Group className="newActivity-formGroup-left">
            <div className="newActivity-form-item">
              <Form.Label>Titel</Form.Label>
              <Form.Control
                name="title"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput(e)
                }
              />
            </div>
            <div className="newActivity-form-item">
              <Form.Label>Person</Form.Label>
              <Autocomplete
                size="small"
                className={`newActivity-form-autocomplete ${
                  isRequired ? "requiredField-case-baseInfo" : ""
                }`}
                options={allUsers as User[]}
                getOptionLabel={(option: userOption) => option.name}
                renderInput={(params) => <TextField {...params} />}
                onInputChange={handlePersonSelect}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                popupIcon={
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="xs"
                    className="newActivity-dropdown-icon"
                  />
                }
              />
            </div>
            <div className="newActivity-form-item">
              <Form.Label>Från</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(e: Date) => handleStartDateChange(e)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showWeekNumbers
                calendarStartDay={1}
                dateFormat="yy-MM-dd"
                onBlur={setCombinedStartDate}
              />
              <TimePicker
                disableClock
                hourPlaceholder="00"
                minutePlaceholder="00"
                format="HH:mm"
                locale="sv-SE"
                onChange={(e) => setStartTime(e)}
                value={startTime}
                onBlur={setCombinedStartDate}
                disabled={isAllDay}
              />
            </div>
            <div className="newActivity-form-item">
              <Form.Label>Till</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={(e: Date) => handleEndDateChange(e)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                showWeekNumbers
                calendarStartDay={1}
                dateFormat="yy-MM-dd"
                onBlur={setCombinedEndDate}
              />
              <TimePicker
                disableClock
                hourPlaceholder="00"
                minutePlaceholder="00"
                format="HH:mm"
                locale="sv-SE"
                onChange={(e) => setEndTime(e)}
                value={endTime}
                onBlur={setCombinedEndDate}
                disabled={isAllDay}
              />
            </div>
            <div className="newActivity-form-allDay">
              <Form.Label>Heldag</Form.Label>
              <Form.Check
                name="isAllDay"
                className="allDay-checkbox-newActivity"
                onClick={() => setIsAllDay(!isAllDay)}
                onChange={handleStatus}
              />
            </div>
            <div className="newActivity-form-resource">
              <Form.Label>Resurs</Form.Label>
              <Form.Control
                type="number"
                min={0}
                step={0.5}
                name="resourceTime"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput(e)
                }
                value={timeDiff}
              />
              <Form.Select name="resourceType" onChange={(e) => handleInput(e)}>
                {resourceList.map((res, index) => (
                  <option key={index}>{res}</option>
                ))}
              </Form.Select>
            </div>
            <div className="newActivity-form-item">
              <Form.Label>kategori</Form.Label>
              <Form.Select name="category" onChange={(e) => handleInput(e)}>
                {categoryOptions.map((res) => (
                  <option key={res.key}>{res.label}</option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
          <Form.Group className="newActivity-formGroup-right">
            <div className="newActivity-form-item">
              <Form.Label>Beskrivning</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={8}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInput(e)
                }
              />
            </div>
          </Form.Group>
        </Form>
        <Form.Group className="newActivity-formGroup-checkBox">
          <Form.Check label="Färdig(prel)" name="finished" />
          <Form.Check label="Privat" name="isPrivate" onChange={handleStatus} />
          <Form.Check label="Låst" name="isLocked" onChange={handleStatus} />
          <Form.Check label="Påbörjad" />
          <Form.Check
            label="Väntar på svar"
            name="isAwaitingFeedback"
            onChange={handleStatus}
          />
          <Form.Check
            label="Överenskommelse med kund"
            name="isCustomerAgreement"
            onChange={handleStatus}
          />
        </Form.Group>
        <Form.Group className="newActivity-formGroup-buttons">
          <div className="newActivity-buttonGroup">
            <Button
              variant="outline-danger"
              onClick={() => {
                if (newActivityActive) {
                  setnewActivityActive(false);
                }
              }}
            >
              Ångra
            </Button>
            <Button variant="outline-dark" onClick={postActivity}>
              Spara
            </Button>
          </div>
        </Form.Group>
      </>
    );
  };
  return renderNewActivity();
}
