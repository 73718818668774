import "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./Bookings.css";
import { SchedulerEvent } from "../../../../Profile/Views/Calendar/Calendar";
import { Activity } from "../../../../Errand/Models/Activity";
import { Case } from "../../../../Errand/Models/Case";
import { getAllCaseActivities } from "../../../../Errand/Services/ActivityService";
import { Customer } from "../../../Models/Customer/Customer";
import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { User } from "../../../../Users/Models/User";
import { useNavigate } from "react-router";
import { getAllUsers } from "../../../../Users/Services/UserServices";

interface BookingsProp {
  currentCustomer?: Customer;
}

interface UniqueEvent {
  [id: string]: SchedulerEvent;
}

export function Bookings({ currentCustomer }: BookingsProp) {
  const [customer, setCustomer] = useState<Customer>();
  const [cases, setCases] = useState<Case[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<User[]>();
  const scheduler = window.scheduler;

  const navigate = useNavigate();

  useEffect(() => {
    scheduler.plugins({
      agenda_view: true,
    });
    scheduler.config.load_date = "%Y-%m-%d %H:%i";
    scheduler.i18n.setLocale("sv");
    scheduler.setLoadMode("day");
    scheduler.locale.labels.agenda_tab = "Försenade bokningar";
    scheduler.locale.labels.full_day = "";
    scheduler.config.agenda_start = new Date(2000, 1, 1);
    scheduler.config.agenda_end = new Date();
    scheduler.config.header = null;
    scheduler.setSkin("");
    scheduler.init("booking_scheduler", new Date(), "month");

    return () => {
      scheduler.clearAll();
      scheduler.detachAllEvents();
    };
  }, [currentCustomer, scheduler]);

  useEffect(() => {
    if (activities.length > 0) {
      const schedulerEvents = reloadScheduler();
      scheduler.clearAll();
      scheduler.parse(schedulerEvents);
    }
  }, [activities, toggle]);

  useEffect(() => {
    if (!currentCustomer && getStorage("customer")) {
      if (getStorage("customer") !== undefined)
        setCustomer(getStorage("customer"));
    } else if (
      (currentCustomer && !getStorage("customer")) ||
      (currentCustomer && getStorage("customer"))
    ) {
      setCustomer(currentCustomer);
      setStorage("customer", currentCustomer);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (customer?.relations.cases) {
      setCases(customer?.relations.cases as Case[]);
    }
    setActivities([]);
  }, [currentCustomer, customer, cases]);

  useEffect(() => {
    if (cases) {
      const getAllActivities = () => {
        cases.map(async (res) => {
          const data = await getAllCaseActivities(res.id);
          setActivities((prev) => [...prev, ...data]);
        });
      };
      getAllActivities();
    }
  }, [cases]);

  useEffect(() => {
    if (!allUsers?.length && getStorage("allUsers")) {
      setAllUsers(getStorage("allUsers"));
    } else if (allUsers && !getStorage("allUsers")) {
      setStorage("allUsers", allUsers);
    } else if (!allUsers?.length && !getStorage("allUsers")) {
      const fetchUsers = async () => {
        try {
          const data = await getAllUsers();
          setAllUsers(data);
          setStorage("allUsers", data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    if (activities) {
      scheduler.templates.event_bar_text = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        return ev.text;
      };

      scheduler.templates.agenda_time = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        return "";
      };

      scheduler.templates.agenda_date = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        return "";
      };

      scheduler.templates.agenda_text = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        const renderUser = () => {
          if (ev.userId === undefined || null) {
            return "";
          } else {
            const user = allUsers?.find((res) => res.id === ev.userId);
            return user ? user.name : "";
          }
        };

        return `
          <div class="textContainer-bookings">
            <div class="eventText-bookings">${ev.text}</div>
            <div class="userText-bookings">
              <b>${renderUser()}</b>
            </div>
          </div>
          `;
      };

      scheduler.templates.event_bar_date = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        return "";
      };

      scheduler.templates.event_class = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        const currentDate = new Date();
        const endDate = new Date(end);
        currentDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        const isLate = endDate < currentDate;

        return isLate ? "event-late" : "";
      };

      if (scheduler.getState().mode === "agenda") {
        scheduler.templates.event_class = (
          start: Date,
          end: Date,
          ev: SchedulerEvent
        ) => {
          return "expired-events";
        };

        scheduler.clearAll();
        scheduler.parse(filterEvents(reloadScheduler()));
      } else {
        scheduler.clearAll();
        scheduler.parse(reloadScheduler());
      }

      scheduler.templates.tooltip_text = (
        start: Date,
        end: Date,
        ev: SchedulerEvent
      ) => {
        const filteredActivity = activities.find((res) => res.id === ev.id);
        if (cases) {
          const filteredCase = cases.find(
            (res) => res.id === filteredActivity?.caseId
          );
          return `${ev.text}</br><u>${filteredCase?.caseNumber} ${filteredCase?.title}</u>`;
        }
      };

      scheduler.attachEvent("onClick", (id: string) => {
        scheduler.clearAll();
        const filteredActivity = activities.find((res) => res.id === id);
        if (cases) {
          const filteredCase = cases.find(
            (res) => res.id === filteredActivity?.caseId
          );
          setStorage("currentCase", filteredCase);
          navigate("/arende/grund");
        }
      });
    }

    return () => {
      scheduler.detachEvent("onClick");
      scheduler.clearAll();
    };
  }, [activities, scheduler.getState().mode]);

  const reloadScheduler = () => {
    scheduler.clearAll();
    const schedulerEvents = activities.map((res) => ({
      id: res.id,
      start_date: res.start,
      end_date: res.end,
      text: res.title,
      caseId: res.caseId,
      case: getCaseName(res.caseId as string),
      full_day: res.isAllDay,
      isBegun: res.isBegun,
      isFinished: res.isFinished,
      isAwaitingFeedback: res.isAwaitingFeedback,
      isCustomerAgreement: res.isCustomerAgreement,
      isLocked: res.isLocked,
      userId: res.userId,
    }));
    return schedulerEvents;
  };

  const filterEvents = (events: SchedulerEvent[]) => {
    const uniqueEvents: UniqueEvent = {};
    events.forEach((event) => {
      if (event.id) {
        if (event.start_date < event.end_date) {
          event.end_date = event.start_date;
        }
        uniqueEvents[event.id] = event;
      }
    });
    return Object.values(uniqueEvents);
  };

  const getCaseName = (id: string) => {
    const obj = cases.find((res) => res.id === id);
    return obj?.title;
  };

  const late = scheduler.getState().mode === "agenda";

  return (
    <>
      <Card className="cardContainer-customer-bookings">
        <div className="sticky-top">
          <Card.Title className="cardContainerTitle">
            <h2 className="ps-3 align-self-center">Bokningar</h2>
          </Card.Title>
        </div>
        <Card.Body className="cardBody-customer-bookings">
          <div id="booking_scheduler" className="dhx_cal_container">
            <div className="dhx_cal_navline" id="navbar-bookings">
              {!late ? (
                <>
                  <div className="dhx_cal_prev_button">&nbsp;</div>
                  <div className="dhx_cal_next_button">&nbsp;</div>
                </>
              ) : (
                ""
              )}
              <div className="dhx_cal_date" id="date-bookings" />
              <div
                className="dhx_cal_tab"
                id="montTab-bookings"
                data-tab="month"
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></div>
              <div
                className="dhx_cal_tab"
                data-tab="agenda"
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></div>
            </div>
            <div className="dhx_cal_header" />
            <div
              className="dhx_cal_data"
              id={
                scheduler.getState().mode === "agenda"
                  ? "expired-bookings"
                  : "data-bookings"
              }
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
