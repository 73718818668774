import { Card } from "react-bootstrap";

export function TimeReport() {
  return (
    <Card className="cardContainer-timeReport">
      <div className="cardTitle-case-base sticky-top">
        <Card.Title className="cardContainerTitle">
          <h2 className="ps-3 align-self-center">Tidsredovisning</h2>
        </Card.Title>
      </div>
      <Card.Body className="infoBody-timeReport">
        <h3 className="d-flex justify-content-center">Kommer snart</h3>
      </Card.Body>
    </Card>
  );
}
