import { useEffect, useRef, useState } from "react";
import { CurrentErrand } from "./CurrentErrand";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  faUserPlus,
  faCirclePlus,
  faList,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Tooltip } from "@mui/material";
import {
  getCustomerById,
  postNewCustomer,
} from "../../Services/CustomerService";
import {
  Customer,
  customerOption,
  defaultCustomer,
} from "../../Models/Customer/Customer";
import {
  getStorage,
  removeStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import "../Base/Base.css";
import { fetchAllCustomers } from "../../Services/Handlers/CustomerHandlers/CustomerHandler";
import { Bookings } from "./Bookings/Bookings";
import { useAlert } from "../../../Root/Handlers/Alerts/Alerts";

export function Current() {
  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [searchInput, setSearchInput] = useState("");
  const [newCustomerActive, setNewCustomerActive] = useState<boolean>(false);
  const [newCaseActive, setNewCaseActive] = useState<boolean>(false);
  const [newCustomer, setNewCustomer] = useState<Customer>(defaultCustomer);
  const [inputCustomer, setInputCustomer] = useState<Customer | null>(null);

  const isMounted = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);

  const { showAlert } = useAlert();

  useEffect(() => {
    if (allCustomers.length) {
      setStorage("allCustomers", allCustomers);
    }
  }, [allCustomers]);

  useEffect(() => {
    if (!getStorage("allCustomers")) {
      fetchAllCustomers(
        allCustomers,
        customer,
        setAllCustomers,
        setCustomer as React.Dispatch<React.SetStateAction<Customer>>
      );
    } else {
      setAllCustomers(getStorage("allCustomers"));
      if (getStorage("customer")) {
        setCustomer(getStorage("customer"));
      }
    }
  }, [newCaseActive]);

  //förhindrar att sidan laddas om om "enter" trycks fler ggr
  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    const fetchCustomerById = async () => {
      if (!searchInput) {
        return;
      }
      if (!isMounted.current) {
        try {
          const data = await getCustomerById(searchInput);
          setCustomer(data);
          setStorage("cases", data.relations.cases);
        } catch (error) {
          console.error("Failed trying to fetch customer", error);
        }
      } else {
        isMounted.current = true;
      }
    };

    fetchCustomerById();
  }, [searchInput]);

  const handleInputChange = (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => {
    if (reason === "reset" && option === "") {
    } else {
      if (option.length >= 10) {
        const input = option.split(" ")[0];
        allCustomers?.filter((match) =>
          match.customerNumber === input ? setSearchInput(match.id) : ""
        );
      } else {
        setSearchInput("");
      }
    }
  };

  const createNewCustomer = async () => {
    if (!newCustomer) return;
    try {
      const data = await postNewCustomer(newCustomer.name);
      showAlert(`Kund: ${newCustomer.name} skapades`, "success");
      setAllCustomers((prev) => [...prev, data]);
      const customerData = await getCustomerById(data.id);
      setCustomer(customerData);
      setNewCustomerActive(false);
      removeStorage("currentCase");
    } catch (error) {
      showAlert(
        `Kund: Det gick ej att skapa kund: ${newCustomer.name}`,
        "danger"
      );
      console.error("Failed trying to post new customer", error);
    }
  };

  const addNameToCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="contentBody">
        <div className="content-case-base">
          <div
            className={
              newCustomerActive
                ? "searchCustomer-newCustomer"
                : "searchCustomer"
            }
          >
            <Form ref={formRef}>
              <Form.Group className="searchCustomer-form">
                <div className="searchAndTitle-customer-base">
                  <Autocomplete
                    className="searchContainer-customer-base"
                    options={allCustomers?.map((res) => res)}
                    getOptionLabel={(option: customerOption) =>
                      `${option.customerNumber} ${option.name}`
                    }
                    renderInput={(params) => (
                      <TextField label="Sök kund" {...params} />
                    )}
                    size="small"
                    autoHighlight={true}
                    onChange={(e, val) => setInputCustomer(val)}
                    value={inputCustomer as Customer}
                    onInputChange={handleInputChange}
                  />
                  <h3 className="customerTitle-customer-base">
                    {!customer
                      ? "Ingen kund vald"
                      : `${customer?.customerNumber} - ${customer?.name}`}
                  </h3>
                </div>
                <div className="titleIcons-customer-base">
                  <Tooltip title="Ny kund">
                    <Button
                      variant="outline-dark"
                      onClick={() => setNewCustomerActive(!newCustomerActive)}
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className="newCustomer-customer-base"
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Nytt ärende">
                    <div className="icon-button-header">
                      <Button
                        variant="outline-dark"
                        onClick={() => setNewCaseActive(!newCaseActive)}
                        disabled={!customer}
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="newErrand-customer-base"
                        />
                      </Button>
                    </div>
                  </Tooltip>
                  <span>|</span>
                  <Tooltip title="Ärendelista, kommer snart">
                    <div className="icon-button-header">
                      <Button
                        variant="outline-dark"
                        disabled={true /* !customer */}
                      >
                        <FontAwesomeIcon
                          icon={faList}
                          className="errandList-customer-base"
                        />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </Form.Group>
            </Form>
            {newCustomerActive ? (
              <div className="newCustomerContainer">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Ange kundnamn"
                  onChange={addNameToCustomer}
                />
                <Button
                  variant="outline-dark"
                  onClick={() => createNewCustomer()}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            ) : null}
          </div>
          <span className="contentContainer">
            <Row className="columnContainer-current">
              <CurrentErrand
                currentCustomer={customer}
                setCurrentCustomer={
                  setCustomer as React.Dispatch<React.SetStateAction<Customer>>
                }
                newCaseActive={newCaseActive}
                setNewCaseActive={setNewCaseActive}
              />
              <Col>
                <Bookings currentCustomer={customer} />
              </Col>
            </Row>
          </span>
        </div>
      </div>
    </>
  );
}
