import React from "react";
import "./Error404.css";

export function ErrorPage() {
  return (
    <div className="error-page-background">
      <div className="error-page">
        <div className="error-content">
          <h1>404</h1>
          <p>Sidan du sökte kunde inte hittas.</p>
          <p>
            Det kan bero på att sidan inte finns eller att en länk var felaktig.
          </p>
          <a href="/intranat/ledning" className="home-link">
            Gå till Startsidan
          </a>
        </div>
      </div>
    </div>
  );
}
