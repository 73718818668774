import React, { useState, useEffect } from "react";
import { removeStorage } from "../../../../GlobalServices/SessionStorageService";
import "../Management/Management.css";
import { Card, Modal, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import quill styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { User } from "../../../Users/Models/User";
import { getCurrentUser } from "../../../Users/Services/UserServices";

export const Management: React.FC = () => {
  useEffect(() => {
    removeStorage("password");
  }, []);

  const [messages, setMessages] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getCurrentUser();
      setUser(data);
    };
    fetchUser();
  }, []);

  const handlePostMessage = () => {
    if (editorHtml.trim()) {
      if (editingIndex !== null) {
        // Update the existing message
        const updatedMessages = [...messages];
        updatedMessages[editingIndex] = editorHtml;
        setMessages(updatedMessages);
        setEditingIndex(null); // Reset editing index
      } else {
        setMessages([editorHtml, ...messages]);
      }
      setEditorHtml("");
      setShowModal(false);
    }
  };

  const handleEditMessage = (index: number) => {
    setEditingIndex(index);
    setEditorHtml(messages[index]);
    setShowModal(true);
  };

  const handleDeletePost = () => {
    if (editingIndex !== null) {
      // Filter out the post at editingIndex
      const updatedMessages = messages.filter(
        (_, index) => index !== editingIndex
      );
      setMessages(updatedMessages);
      setEditingIndex(null);
      setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setEditorHtml("");
    setEditingIndex(null);
    setShowModal(false);
  };

  return (
    <div className="content-intranet">
      <div className="intranet-management-body">
        <div className="intranet-card-container">
          <Card className="intranet-info-card">
            <Card.Header className="intranet-header sticky-top">
              <Card.Title className="intranet-card-title">
                <h2>Nyheter</h2>
                <Button
                  variant="outline-dark"
                  onClick={() => setShowModal(true)}
                  style={{ marginLeft: "auto" }}
                >
                  Nytt Inlägg
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="newsfeed">
                {messages.map((message, index) => (
                  <div key={index} className="message">
                    <div className="poster-name">{user?.name}</div>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                    <div className="edit-button">
                      <Button
                        size="sm"
                        onClick={() => handleEditMessage(index)}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="intranet-card-container">
          <Card className="intranet-stats-card">
            <Card.Header className="intranet-header sticky-top">
              <Card.Title className="intranet-card-title">
                <h2>Statistik</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-center"></div>

              <div className="d-flex flex-column">
                <h4 className="align-self-center">
                  Changelog v.0.2.3 - *2024-05-16*
                </h4>
                <ul className="p-4">
                  <li>
                    <b>Nytt:</b> Layout skapad för redigering av aktivitet
                  </li>
                  <li>
                    <b>Ny funktion:</b> Filtrering av kontakt i kund-grund
                  </li>
                  <li>
                    <b>Ny funktion:</b> Filtrering av ärenden i kund-aktuellt
                  </li>
                  <li>
                    <b>fix: </b> Stylingfix för responsivitet mellan 2k och 1080
                  </li>
                  <li>
                    <b>Buggfix:</b> Ärende går nu att skapa igen i Ärende-grund
                  </li>
                  <li>
                    <b>Buggfix:</b> Ärende ska hänga med från kund till ärende
                    vid skapande av nytt ärende
                  </li>
                  <li>
                    <b>Buggfix: </b> Minor fixes
                  </li>
                </ul>

                <h4 className="align-self-center">
                  Changelog v.0.2.2 - *2024-04-22*
                </h4>
                <ul className="p-4">
                  <li>
                    <b>fix/funktion:</b> Kalendern är nu kopplad till inloggad
                    användare, fix för färgläggning av kategorier
                  </li>
                  <li>
                    <b>fix:</b> Sidan ska vara mer responsiv
                  </li>
                  <li>
                    <b>Buggfix:</b> flik ska stängas vid skapande av aktivitet
                  </li>
                  <li>
                    <b>Buggfix:</b> data i prioritet samt intern status skickas
                    inte till db vid redigering av ärende
                  </li>
                  <li>
                    <b>Buggfix:</b> kundansvarig går nu att välja i kund-grund
                  </li>
                  <li>
                    <b>Buggfix: </b> bugg vid liten skärm i navigationsbaren
                    fixad
                  </li>
                  <li>
                    <b>Buggfix: </b> Rätt ärenden visas i sökrutan vid
                    navigering från andra sidor till ärende-grund sidan
                  </li>
                </ul>
                <h4 className="align-self-center">
                  Changelog v.0.2.1 - *2024-04-15*
                </h4>
                <ul className="p-4">
                  <li>
                    <b>Buggfix:</b> sidan kraschar när tid töms i skapande av
                    aktivitet i planering
                  </li>
                  <li>
                    <b>Buggfix:</b> Kund uppdateras ej i redigering, Kund-Grund
                  </li>
                  <li>
                    <b>Buggfix:</b> Skapande av aktivitet i planering ska nu
                    fungera klockrent
                  </li>
                  <li>
                    <b>Buggfix:</b> Rätt ärende visas nu vid navigering från
                    skapande av nytt ärende på kundsidan
                  </li>
                  <li>
                    <b>Buggfix:</b> "Skapa ärende"-flik stängs nu vid skapande
                    av ärende på ärendesidan
                  </li>
                  <li>
                    <b>Buggfix: </b> validering vid skapande av ärende/planering
                    där kontakt/person krävs
                  </li>
                </ul>
                <h4 className="align-self-center">
                  Changelog v.0.2.0 - *2024-04-12*
                </h4>
                <ul className="p-4">
                  <li>
                    <b>Kalender overhaul i Min sida - kalender</b>
                  </li>
                  <li>
                    <b>Lagt till bokningsvyn i Kund - aktuellt</b>
                  </li>
                  <p>
                    *Bokningsvyn ska visa alla bokningar på vald kund. TODO:
                    implementera användarens bokningar som ej är kopplade till
                    någon kund*
                  </p>
                  <li>
                    <b>
                      En början på en logg/nyheter/tips-feed, samt Statistik i
                      Intranät
                    </b>
                  </li>
                  *TODO: Ska vara kopplad till databas, kunna filtrera på datum
                  vid skapande samt se äldre inlägg* *Vad kan tänkas att visas i
                  statistik, kom gärna med feedback!*
                  <li>
                    <b>Uppdatering vid skapande av kund/kontakt/aktivitet</b>
                  </li>
                  <li>
                    <b>buggfixar + feedback</b>
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {editingIndex !== null
                ? "Redigera inlägg"
                : "Skriv ett nytt inlägg"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactQuill value={editorHtml} onChange={setEditorHtml} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Stäng
            </Button>
            {editingIndex !== null && (
              <Button variant="danger" onClick={handleDeletePost}>
                Radera
              </Button>
            )}
            <Button variant="primary" onClick={handlePostMessage}>
              {editingIndex !== null ? "Uppdatera" : "Skapa"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
