import { Button, Card, ListGroup, Modal } from "react-bootstrap";
import { Access } from "../../../../Login/Models/User/Access";
import { Case } from "../../../Models/Case";
import {
  faToggleOff,
  faPlus,
  faToggleOn,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "react-time-picker/dist/TimePicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { User } from "../../../../Users/Models/User";
import { NewActivity } from "./newActivity";
import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { Activity } from "../../../Models/Activity";
import { getAllCaseActivities } from "../../../Services/ActivityService";
import { EditActivity } from "./EditActivity";

interface planningProps {
  access?: Access;
  currentCase?: Case;
  users: User[];
}

export function Planning({ currentCase, users }: planningProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [allCases, setAllCases] = useState<Case[]>();
  const [newActivityActive, setnewActivityActive] = useState<boolean>(false);
  const [allUsers, seAlltUsers] = useState<User[]>(users);
  const [allActivities, setAllActivities] = useState<Activity[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();

  useEffect(() => {
    const fetchAllCases = async () => {
      if (!allCases && getStorage("cases")) {
        setAllCases(getStorage("cases"));
      } else if (
        (allCases && !getStorage("cases")) ||
        (allCases && getStorage("cases"))
      ) {
        setStorage("cases", allCases);
      } else if (!allCases && !getStorage("cases")) {
      }
    };
    fetchAllCases();
  }, [allCases]);

  useEffect(() => {
    if (!currentCase?.relations.activities && currentCase) {
      const fetchActivities = async () => {
        const data = await getAllCaseActivities((currentCase as Case).id);
        setAllActivities(data);
        setStorage("activities", data);
      };
      fetchActivities();
    } else {
      if (currentCase) {
        setAllActivities(currentCase?.relations.activities as Activity[]);
        setStorage(
          "activities",
          currentCase?.relations.activities as Activity[]
        );
      }
    }
  }, [currentCase, newActivityActive]);

  const renderTitle = () => {
    return (
      <Card.Title className="cardContainerTitle-planning">
        <h2 className="ps-3 align-self-center">
          {newActivityActive ? "Ny aktivitet" : "Planering"}
        </h2>
        <div
          className={
            newActivityActive
              ? "buttonGroup-case-activity"
              : "buttonGroup-case-planning"
          }
        >
          {newActivityActive ? (
            <Tooltip title="Ångra">
              <div className="icon-button-header">
                <Button variant="outline-dark" className="iconButton-planning">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => setnewActivityActive(!newActivityActive)}
                  />
                </Button>
              </div>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Visa/dölj klarmarkerade">
                {isChecked ? (
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    onClick={() => setIsChecked(!isChecked)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    onClick={() => setIsChecked(!isChecked)}
                  />
                )}
              </Tooltip>
              <Tooltip title="Ny aktivitet">
                <div className="icon-button-header">
                  <Button
                    variant="outline-dark"
                    disabled={!currentCase}
                    className="iconButton-planning"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      onClick={() => setnewActivityActive(!newActivityActive)}
                    />
                  </Button>
                </div>
              </Tooltip>
              {/* <Tooltip title="Ny återkommande aktivitet">
                <FontAwesomeIcon icon={faCirclePlus} />
              </Tooltip> */}
            </>
          )}
        </div>
      </Card.Title>
    );
  };

  const formatDate = (inDate: Date) => {
    const convertDate = inDate.toString();
    const date = convertDate.split("T")[0];
    const time = convertDate.split("T")[1];
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    const splitTime = hours + ":" + minutes;

    return date + " " + splitTime;
  };

  const showModal = (activity: Activity) => {
    setSelectedActivity(activity);
    setShowEditModal(true);
  };

  const renderFinishedActivities = (activity: Activity, index: number) => {
    if (activity.isFinished)
      return (
        <>
          <ListGroup.Item
            key={index}
            onClick={() => showModal(activity)}
            className="planningCase-case-finished event-finished"
            action
          >
            <span className="item-activity" key={index}>
              <div className="title-activity">{activity.title}</div>
              <div className="resourceTime-activity">
                {activity.resourceTime} h
              </div>
              <div className="startDate-activity">
                {formatDate(activity.start as Date)} -
              </div>
              <div className="endDate-activity">
                {formatDate(activity.end as Date)}
              </div>
            </span>
          </ListGroup.Item>
        </>
      );
  };

  const renderUnfinishedActivities = (activity: Activity, index: number) => {
    const date = new Date(activity.end);
    if (!activity.isFinished)
      return (
        <ListGroup.Item
          key={index}
          onClick={() => showModal(activity)}
          className={
            date < new Date()
              ? "planningCase-case event-late"
              : "planningCase-case"
          }
          action
        >
          <span className="item-activity" key={index}>
            <div className="title-activity">{activity.title}</div>
            <div className="resourceTime-activity">
              {activity.resourceTime} h
            </div>
            <div className="startDate-activity">
              {formatDate(activity.start as Date)} -
            </div>
            <div className="endDate-activity">
              {formatDate(activity.end as Date)}
            </div>
          </span>
        </ListGroup.Item>
      );
  };

  const renderActivitys = () => {
    return (
      <ListGroup className="listGroup-case-planning" variant="flush">
        <span className="legendContainer-activity">
          <div className="title-legend-activity">Titel</div>
          <div className="resourceTime-legend-activity">Resurs</div>
          <div className="start-legend-activity">Starttid</div>
          <div className="end-legend-activity">Sluttid</div>
        </span>
        {allActivities?.map((res, index) => {
          if (isChecked) {
            return renderFinishedActivities(res, index);
          } else {
            return renderUnfinishedActivities(res, index);
          }
        })}
      </ListGroup>
    );
  };

  return (
    <>
      <Card className="cardContainer-planning">
        <div className="cardTitle-case-base sticky-top">{renderTitle()}</div>
        <Card.Body className="infoBody-planning">
          {newActivityActive ? (
            <NewActivity
              newActivityActive={newActivityActive}
              setnewActivityActive={setnewActivityActive}
              currentCase={currentCase as Case}
              users={allUsers as User[]}
              allActivities={allActivities}
            />
          ) : (
            renderActivitys()
          )}
        </Card.Body>
      </Card>
      {showEditModal && (
        <EditActivity
          activity={selectedActivity as Activity}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      )}
    </>
  );
}
