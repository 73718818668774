import React from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faInbox,
  faCog,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import "./DropdownSidebar.css";
import { logoutUser } from "../Users/Services/UserServices";
import { clearStorage } from "../../GlobalServices/SessionStorageService";

interface DropdownSidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DropdownSidebar({ isOpen, onClose }: DropdownSidebarProps) {
  const logOut = async () => {
    const response = await logoutUser();
    if (response === 204 || response === 200) {
      clearStorage();
    } else {
      clearStorage();
      window.location.reload();
    }
  };

  return (
    <Sidebar
      className="MenuContainerSidebar"
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          background: `linear-gradient(to bottom, rgba(76,41,119,1) 0%, rgba(42,20,80,1) 50%, rgba(20,11,38,1) 95%)`,
        },
      }}
      collapsed={!isOpen}
      width="200px"
      style={{
        boxShadow: isOpen ? "-5px 10px 10px rgba(0, 0, 0, 0.5)" : "none",
        right: isOpen ? "-1px" : "-200px",
        transition: "right .3s,left .3s,  box-shadow 0.8s",
      }}
    >
      <Menu className="sidebar-content-topspace flex-container">
        <div className="sidebar-content-div">
          <div className="sidebar-content-topLinks">
            <MenuItem
              component={
                <Link className="dropdown-link" to={"/profil/information"} />
              }
              onClick={onClose}
              className="MenuItemSpacing"
            >
              <FontAwesomeIcon className="IconStyle" icon={faUser} />
              <div className="linkText">Profil</div>
            </MenuItem>

            <MenuItem
              component={
                <Link className="dropdown-link" to={"/profil/inkorg"} />
              }
              onClick={onClose}
              className="MenuItemSpacing"
            >
              <FontAwesomeIcon className="IconStyle" icon={faInbox} /> Inkorg
            </MenuItem>
          </div>
          <div className="sidebar-content-bottomLinks">
            <MenuItem
              component={
                <Link className="dropdown-link" to={"/installningar"} />
              }
              onClick={onClose}
              className="MenuItemSpacingSeparated"
            >
              <FontAwesomeIcon className="IconStyle" icon={faCog} />{" "}
              Inställningar
            </MenuItem>
            <MenuItem onClick={logOut} className="MenuItemSpacingLast">
              <FontAwesomeIcon
                className="IconStyle"
                icon={faArrowRightFromBracket}
              />{" "}
              Logga ut
            </MenuItem>
          </div>
        </div>
      </Menu>
    </Sidebar>
  );
}
