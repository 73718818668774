import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Settings.css";
import "../Root/Handlers/Alerts/Alerts";
import {
  faBuildingColumns,
  faUser,
  faGear,
  faChartBar,
  faPaintbrush,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "../Root/Handlers/Alerts/Alerts";

export function Settings() {
  const [activeTab, setActiveTab] = useState("account");

  const { showAlert } = useAlert();

  const handleTabChange = (SettingsTabs: any) => {
    setActiveTab(SettingsTabs);
  };

  const renderRightSidePage = () => {
    switch (activeTab) {
      // Fler case tabs
      case "balance":
        return (
          <Card>
            <Card.Header className="settings-content-header">
              <div>Plånbok Exempel</div>
            </Card.Header>
            <Card.Body>Innehåll Här</Card.Body>
          </Card>
        );

      case "account":
        return (
          <Card>
            <Card.Header className="settings-content-header">
              <div>Ditt Konto</div>
            </Card.Header>
            <Card.Body className="settings-content-account">
              <div className="settings-main-avatar">
                <img
                  src={`${process.env.PUBLIC_URL}/IMG/Icons/Generic-Profile-Image.png`}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
                <div className="settings-main-avatar-overlay">
                  Byt Profilbild
                </div>
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label className="account-label-setting">
                    Ditt namn:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ditt Namn"
                    className="form-control-settings"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="account-label-setting">
                    E-post:
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@example.com"
                    className="form-control-settings"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label className="account-label-setting">
                    Lösenord:
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="••••••••"
                    className="form-control-settings"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formRole">
                  <Form.Label className="account-label-setting">
                    Roll:
                  </Form.Label>
                  <Form.Select>
                    <option>Välj roll</option>
                    <option value="user">Användare</option>
                    <option value="admin">Administratör</option>
                  </Form.Select>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Spara
                </Button>
                <Button variant="secondary" className="ms-2">
                  Avbryt
                </Button>
              </Form>
            </Card.Body>
          </Card>
        );

      case "settings":
        return (
          <Card>
            <Card.Header className="settings-content-header">
              <div>Inställningar</div>
            </Card.Header>
            <Card.Body>Innehåll Här</Card.Body>
          </Card>
        );

      case "statistics":
        return (
          <Card>
            <Card.Header className="settings-content-header">
              <div>Statistik</div>
            </Card.Header>
            <Card.Body> Innehåll Här</Card.Body>
          </Card>
        );
      case "colors":
        return (
          <Card>
            <Card.Header className="settings-content-header">
              <div>Temainställningar</div>
            </Card.Header>
            <Card.Body>
              <div className="theme-settings">
                <Form>
                  <h5>Färgscheman</h5>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Mörkt tema"
                    className="mb-2"
                  />
                  <Form.Check
                    type="switch"
                    id="custom-switch-blue"
                    label="Blått tema"
                    className="mb-2"
                  />
                  <Form.Check
                    type="switch"
                    id="custom-switch-green"
                    label="Grönt tema"
                    className="mb-2"
                  />
                </Form>
              </div>
            </Card.Body>
          </Card>
        );

      default:
        return <div>Ofärdig </div>;
    }
  };

  return (
    <>
      <div className="contentBody">
        <div className="customerBody">
          <div>
            <div className="settings-123">
              <div className="settings-content-left">
                <Card className="">
                  <div className="settings-sidebar-header">
                    <img
                      className="settings-sidebar-avatar"
                      src={`${process.env.PUBLIC_URL}/IMG/Icons/Generic-Profile-Image.png`}
                      alt="Profile"
                    />
                    <div className="settings-sidebar-username">
                      Aron Ask (användaren)
                    </div>
                  </div>

                  <div className="settings-sidebar-balance">
                    <div
                      className={`settings-sidebar-balance-item ${
                        activeTab === "balance"
                          ? "settings-sidebar-balance-item--active"
                          : ""
                      }`}
                      onClick={() => handleTabChange("balance")}
                    >
                      <FontAwesomeIcon icon={faBuildingColumns} className="i" />
                      <span>Plånbok Exempel</span>
                      <div className="settings-sidebar-balance-label">420</div>
                    </div>

                    <div
                      className={`settings-sidebar-account-item ${
                        activeTab === "account"
                          ? "settings-sidebar-account-item--active"
                          : ""
                      }`}
                      onClick={() => handleTabChange("account")}
                    >
                      <FontAwesomeIcon icon={faUser} />
                      <span>Konto</span>
                      <div className="settings-sidebar-account-label"></div>
                    </div>

                    <div
                      className={`settings-sidebar-settings-item ${
                        activeTab === "settings"
                          ? "settings-sidebar-settings-item--active"
                          : ""
                      }`}
                      onClick={() => handleTabChange("settings")}
                    >
                      <FontAwesomeIcon icon={faGear} />
                      <span>Generella Inställningar</span>
                      <div className="settings-sidebar-settings-label"></div>
                    </div>

                    <div
                      className={`settings-sidebar-statistics-item ${
                        activeTab === "statistics"
                          ? "settings-sidebar-statistics-item--active"
                          : ""
                      }`}
                      onClick={() => handleTabChange("statistics")}
                    >
                      <FontAwesomeIcon icon={faChartBar} />
                      <span>Statisitk</span>
                      <div className="settings-sidebar-statistics-label"></div>
                    </div>

                    <div
                      className={`settings-sidebar-colors-item ${
                        activeTab === "colors"
                          ? "settings-sidebar-colors-item--active"
                          : ""
                      }`}
                      onClick={() => handleTabChange("colors")}
                    >
                      <FontAwesomeIcon icon={faPaintbrush} />
                      <span>Färger</span>
                      <div className="settings-sidebar-colors-label"></div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="settings-content-right">
                {renderRightSidePage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
