import React from "react";
import { SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faCheck,
  faCalendarAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { SidebarLink } from "../../MainMenuSidebar/MainMenuSidebar";

interface MyPageMenuProps {
  isCollapsed: boolean;
}

const MyPageMenu: React.FC<MyPageMenuProps & { isActive: boolean }> = ({
  isCollapsed,
  isActive,
}) => {
  return (
    <SubMenu
      title="Min sida"
      defaultOpen={isActive}
      label={
        <span>
          <FontAwesomeIcon
            className="MainSidebarMainCategoryIcon"
            icon={faUserAlt}
          />
          {!isCollapsed ? "" : " Min sida"}
        </span>
      }
      className="MainSidebarMainCategoryText"
    >
      {/* <SidebarLink to="/profil/todo" icon={faCheck} label="Todo" /> */}
      <SidebarLink
        to="/profil/kalender"
        icon={faCalendarAlt}
        label="Kalender"
      />
    </SubMenu>
  );
};

export default MyPageMenu;
