import React, { SyntheticEvent, useState } from "react";
import { Modal, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import "../Base.css";
import { Contact } from "../../../Models/Contact/Contact";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { listInput } from "../../../Services/Handlers/ContactHandlers/MoveContactHandler";
import { Customer } from "../../../Models/Customer/Customer";
import {
  deleteContactProps,
  getContactProps,
} from "../../../Services/ContactPropService";
import { ContactProp } from "../../../Models/Contact/ContactProp";
import { deleteContact } from "../../../Services/ContactService";

interface contactView {
  setEditModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setContactProps: React.Dispatch<React.SetStateAction<ContactProp[]>>;
  contactProps: ContactProp[];
  contact: Contact;
  allCustomers: Customer[];
}

interface customerOption {
  customerNumber: string;
  name: string;
}

export const EditContactView = ({
  allCustomers,
  contact,
  contactProps,
  setContactProps,
  setEditModalShow,
  setDeleteIsChecked,
}: contactView) => {
  const [moveContact, setMoveContact] = useState("");

  const sortgetContactPropsNames = (data: ContactProp) => {
    if (!data.name) {
    } else return data.name;
  };

  const handleAddProperty = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const props = (prevProps: ContactProp[]) => addPropItem(prevProps);
  };

  const addPropItem = (prevProps: ContactProp[]) => [
    ...prevProps,
    {
      deleted: Date.now(),
      createdBy: "",
      contactId: "",
      created: Date.now(),
      name: "",
      modified: Date.now(),
      modifiedBy: "",
      id: "",
      value: "",
      deletedBy: "",
    },
  ];

  const addgetContactPropsertyButton = () => (
    <Tooltip title="Lägg till egenskap">
      <Button
        variant="outline-dark"
        className="editContact-addProperty"
        onClick={(e) => {
          e.preventDefault();
          handleAddProperty(e);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Tooltip>
  );

  const updateCustomerProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    //spara värde utan att uppdatera state
  };

  const handleRemoveProperty = (
    e: React.MouseEvent<HTMLButtonElement>,
    propToRemove: ContactProp
  ) => {
    e.preventDefault();
    const propList = (prevProps: ContactProp[]) =>
      prevProps.filter((prop) => prop !== propToRemove);
    setContactProps(propList);
  };

  const editContactForms = () => {
    return (
      <>
        <Form.Label>Namn</Form.Label>
        <Form.Control defaultValue={""} />
        <div className="editContact-checkBox-container">
          <Form.Label>Markera som inaktiv</Form.Label>
          <Form.Check className="editContact-checkBox" />
        </div>
        {contactProps.map((res) => (
          <div key={res.value} className="editContact-properties-container">
            <Autocomplete
              className="editContainer-properties"
              options={contactProps.map((reg) => reg.name)}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={sortgetContactPropsNames(res)}
              size="small"
            />
            <Form.Control
              name="value"
              key={res.id}
              defaultValue={res.value}
              onChange={updateCustomerProperty}
            />
            <Tooltip title="Ta bort egenskap">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveProperty(e, res);
                }}
                className="removePropertyButton"
              >
                <FontAwesomeIcon icon={faXmark} size="lg" />
              </Button>
            </Tooltip>
          </div>
        ))}
        {addgetContactPropsertyButton()}
      </>
    );
  };

  const moveContactInputWrapper = (
    e: SyntheticEvent,
    value: string,
    reason: string
  ) => {
    const val = listInput(value);
    setMoveContact(val);
  };

  const editContactMove = () => (
    <>
      <Form.Label>Flytta kontakt till kund</Form.Label>
      <Autocomplete
        className="moveContact-autocomplete"
        options={allCustomers && allCustomers.map((res) => res)}
        getOptionLabel={(option: customerOption) =>
          `${option.customerNumber} ${option.name}`
        }
        renderInput={(params) => <TextField {...params} label="Sök" />}
        size="small"
        autoHighlight={true}
        onInputChange={moveContactInputWrapper}
      />
    </>
  );

  const deleteContactAndProps = async () => {
    contactProps.map((res) => deleteContactProps(res.id as string));
    deleteContact(contact.id);
  };

  const deleteContactView = () => (
    <Popover className="deleteContact-popover">
      <Popover.Body className="deleteContact-popover-body">
        <div>
          Är du säker på att du vill ta bort kontakten:{" "}
          <strong>{contact.name}</strong>
        </div>
        <div className="deleteContact-popover-buttons">
          <Button variant="outline-dark" onClick={() => document.body.click()}>
            Avbryt
          </Button>
          <Button variant="outline-dark" onClick={deleteContactAndProps}>
            Ta bort
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="editContact-modal-header-container">
        <Modal.Header closeButton className="editContact-header">
          <Modal.Title className="editContact-title">
            Redigera kontakt
          </Modal.Title>

          <Modal.Title className="moveContact-title">
            Flytta kontakt
          </Modal.Title>
        </Modal.Header>
      </div>
      <Modal.Body className="editContact-body">
        <Form className="editContact-form">
          <Form.Group
            className="editContact-formGroup"
            controlId="editContactBasicForm"
          >
            {editContactForms()}
          </Form.Group>
          <Form.Group
            className="moveContact-formGroup"
            controlId="moveContactBasicForm"
          >
            {editContactMove()}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="editContact-footer">
        <div className="saveEditButton-editContact">
          <Button
            variant="outline-dark"
            onClick={() => setEditModalShow(false)}
          >
            Spara
          </Button>
        </div>
        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={deleteContactView()}
          rootClose
        >
          <Button
            className="editContact-deleteButton"
            variant="danger"
            onClick={() => setDeleteIsChecked(true)}
          >
            Radera kontakt
          </Button>
        </OverlayTrigger>
      </Modal.Footer>
    </>
  );
};
