import React, { useEffect, useState } from "react";
import { Nav, Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DropdownSidebar } from "../DropdownSidebar/DropdownSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navbar.css";
import { FaSearch } from "react-icons/fa";

import {
  faAngleRight,
  faXmark,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { User } from "../Users/Models/User";
import { getCurrentUser } from "../Users/Services/UserServices";

export function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [user, setUser] = useState<User>();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getCurrentUser();
      setUser(data);
    };
    fetchUser();
  }, []);

  const primaryColor = "#140B26";

  const thirdiaryColor = "#4C2977";

  const gradientColor = `linear-gradient(to right, ${primaryColor} 10%, ${thirdiaryColor} 90%)`;

  const handleSearchFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.backgroundColor = "#ffffff";
    e.target.style.color = "black";
  };

  const handleSearchBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.backgroundColor = "#00000000";
    e.target.style.color = "white";
    e.target.style.border = "2px solid #ffffff";
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Implement your search functionality here
    const searchTerm = e.target.value;
  };

  return (
    <div>
      <Nav
        className="navbar d-flex justify-content-between align-items-center"
        style={{
          height: "100px",
          fontSize: "xx-large",
          background: gradientColor,
          position: "sticky",
          boxShadow: "0 8px 10px rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="d-flex align-items-center">
          <Nav.Item>
            <Link to="/intranat/ledning">
              <img
                src="/IMG/Icons/ritab_navet_white_grey.png"
                alt="Logo"
                className="navetIcon-Nav"
              />
            </Link>
          </Nav.Item>

          <div className="search-container" style={{ position: "relative" }}>
            <Form className="d-flex align-items-center">
              <FormControl
                type="text"
                placeholder="Sök"
                className="mr-3 px-4 py-3 rounded-5 search-input"
                style={{
                  position: "relative",

                  maxWidth: "100%",
                  backgroundColor: "#00000000",
                  color: "white",
                  border: "2px solid white",
                  transition: "background-color 0.2s ease, color 0.2s ease",
                }}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                onChange={handleSearchChange}
                value={""}
              />
              <div
                className="search-icon"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "50%",
                  transform: "translateY(-60%)",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <FaSearch />
              </div>
            </Form>
          </div>
        </div>

        <div
          className="profileContainer-navbar"
          style={{
            marginLeft: "auto",
            margin: "10px",
          }}
        >
          <div className="bellIcon-navbar">
            <FontAwesomeIcon icon={faBell} />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "8px",

              position: "relative",
            }}
          >
            <img
              src="/IMG/Icons/Generic-Profile-Image.png"
              alt="Profile"
              onClick={toggleSidebar}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginRight: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                outline: "3px solid white",
              }}
            />

            <span
              className="d-none d-xl-inline-block"
              onClick={toggleSidebar}
              style={{
                marginRight: "10px",
                color: "white",
                userSelect: "none",
              }}
            >
              {user?.name}
            </span>
          </div>

          <FontAwesomeIcon
            icon={isSidebarOpen ? faXmark : faAngleRight}
            onClick={toggleSidebar}
            style={{
              color: "white",
              fontSize: "2rem",
              position: "relative",
              width: "2rem",
            }}
          />
        </div>
      </Nav>

      {/* Sidebar */}
      <DropdownSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </div>
  );
}
