import React, { useEffect, useState } from "react";
import { Button, Card, Form, ToggleButtonGroup } from "react-bootstrap";
import { Case, defaultCase } from "../../../Models/Case";
import { Access } from "../../../../Login/Models/User/Access";
import {
  Customer,
  defaultCustomer,
} from "../../../../Customer/Models/Customer/Customer";
import {
  getStorage,
  setStorage,
} from "../../../../../GlobalServices/SessionStorageService";
import { priorityList } from "../../../../Customer/Views/Base/CustomerData";
import {
  Contact,
  emptyContact,
} from "../../../../Customer/Models/Contact/Contact";
import { getSingleContactById } from "../../../../Customer/Services/ContactService";
import { Autocomplete, TextField } from "@mui/material";
import { User, emptyUser } from "../../../../Users/Models/User";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllUsers,
  getCurrentUser,
} from "../../../../Users/Services/UserServices";
import { createCase, updateCase } from "../../../Services/CaseService";
import { NewCase } from "./NewCase";
import { useAlert } from "../../../../Root/Handlers/Alerts/Alerts";
import { checkAfterAllUsers } from "../../../../Users/Handlers/UserHandlers";

interface baseInformationProps {
  access?: Access;
  currentCase?: Case;
  setCurrentCase: React.Dispatch<React.SetStateAction<Case>>;
  users: User[];
  newCaseActive: boolean;
  setNewCaseActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface contactOptions {
  name: string;
}

export function BaseInformation({
  access,
  currentCase,
  setCurrentCase,
  users,
  newCaseActive,
  setNewCaseActive,
}: baseInformationProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [contact, setContact] = useState<Contact | null>(null);
  const [defaultContact, setDefaultContact] = useState<Contact | null>(null);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [defaultUser, setdefaultUser] = useState<User | null>(null);
  const [editCase, setEditCase] = useState<Case>(currentCase as Case);
  const [newCase, setNewCase] = useState<Case>(defaultCase);
  const [requiredContact, setRequiredContact] = useState<boolean>(true);
  const [currentPriority, setCurrentPriority] = useState<string>("");
  const [me, setMe] = useState<User>();

  const { showAlert } = useAlert();

  const hasAccess =
    access?.administrator === 1 ||
    access?.function_errand_information_edit === 1;

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getCurrentUser();
      setMe(data);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    checkAfterAllUsers(allUsers, setAllUsers);

    if (getStorage("customer") && getStorage("customer") !== undefined) {
      setCustomer(getStorage("customer"));
    }
    const getContact = async () => {
      if (currentCase) {
        const data = await getSingleContactById(
          currentCase?.contactId as string
        );
        setContact(data);
        currentCase.priority
          ? setCurrentPriority(currentCase.priority)
          : setCurrentPriority("");
      }
    };
    getContact();
  }, [currentCase, users]);

  useEffect(() => {
    setDefaultContact(
      customer.relations.contacts.find(
        (res) => res.id === currentCase?.contactId
      ) as Contact
    );
  }, [currentCase?.contactId, customer]);

  useEffect(() => {
    if (currentCase?.responsibleUserId === null || undefined) {
      setdefaultUser(null);
    } else {
      setdefaultUser(
        allUsers.find(
          (res) => res.id === currentCase?.responsibleUserId
        ) as User
      );
    }
  }, [allUsers, currentCase?.responsibleUserId]);

  useEffect(() => {
    if (!allUsers.length) {
      const fetchUsers = async () => {
        try {
          const data = await getAllUsers();
          setAllUsers(data);
          setStorage("allUsers", data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchUsers();
    }
  }, [users]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const postEditedCase = async () => {
    const data = await updateCase(currentCase?.id as string, editCase);
    const storageData = getStorage("cases") as Case[];
    storageData.push(data);
    setStorage("cases", storageData);
    setStorage("currentCase", data);
    setCurrentCase(data);
    setEditCase(data);
    handleToggle();
  };

  const postCase = async () => {
    const caseToPost = { ...newCase };

    caseToPost.customerId = customer.id;
    caseToPost.responsibleUserId = me?.id as string;

    if (!newCase.contactId) {
      setRequiredContact(false);
      return showAlert("Välj en kontakt", "danger");
    }
    const data = await createCase(customer?.id as string, caseToPost);
    const storageData = getStorage("cases") as Case[];
    storageData.push(data);
    setStorage("cases", storageData);

    setStorage("currentCase", data);
    setCurrentCase(data);
    setNewCaseActive(false);
    setRequiredContact(true);
    setNewCase(defaultCase);
    showAlert(`${data.caseNumber} - ${data.title} skapades`, "success");
  };

  const renderEditButtonErrand = () => {
    if (hasAccess) {
      return (
        <ToggleButtonGroup type="checkbox" className="ms-auto">
          {isChecked ? (
            <>
              <Button
                type="reset"
                variant="outline-danger"
                id="tbg-btn-1"
                defaultValue="Reset"
                onClick={handleToggle}
                className="resetButton-customer-info"
              >
                Ångra
              </Button>
              <Button
                variant="outline-dark"
                id="tbg-btn-1"
                defaultValue={1}
                onClick={postEditedCase}
                className="editButton-customer-info"
              >
                Spara
              </Button>
            </>
          ) : (
            <Button
              variant="outline-dark"
              id="tbg-btn-1"
              defaultValue={1}
              onClick={handleToggle}
              className="editButton-customer-info"
              disabled={!currentCase}
            >
              Ändra
            </Button>
          )}
        </ToggleButtonGroup>
      );
    }
  };

  const renderNewCaseButtons = () => {
    return (
      <>
        <div className="newCase-buttonGroup-case">
          <Button
            variant="outline-danger"
            onClick={() => setNewCaseActive(!newCaseActive)}
            className="resetButton-customer-info"
          >
            Ångra
          </Button>
          <Button
            variant="outline-dark"
            className="editButton-customer-info"
            onClick={postCase}
          >
            Spara
          </Button>
        </div>
      </>
    );
  };

  const handleEditinput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "priority") {
      setCurrentPriority(value);
    }
    setEditCase((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactSelect = (
    e: React.SyntheticEvent,
    value: string,
    option: string
  ) => {
    const contactObject = customer.relations.contacts.find(
      (res) => res.name === value
    );
    setEditCase((prev) => ({
      ...prev,
      contactId: contactObject?.id as string,
    }));
  };

  const handleResponsibleSelect = (
    e: React.SyntheticEvent,
    value: string,
    option: string
  ) => {
    const user = allUsers.find((res) => res.name === value);
    setEditCase((prev) => ({
      ...prev,
      responsibleUserId: user?.id as string,
    }));
  };

  return (
    <Card className="cardContainer-baseInfo">
      <div className="sticky-top">
        <Card.Title className="cardContainerTitle">
          <h2 className="ps-3 align-self-center">
            {!newCaseActive ? "Grundinformation" : "Nytt ärende"}
          </h2>
          {!newCaseActive ? renderEditButtonErrand() : renderNewCaseButtons()}
        </Card.Title>
      </div>
      <Card.Body className="infoBody-baseInfo">
        {!newCaseActive ? (
          <Form className="formBody-baseInfo">
            <Form.Group
              className="formgroup-container-baseInfo-left"
              controlId={!isChecked ? "formBasicCustomer" : "formEditCustomer"}
            >
              <div className="infoForm-baseInfo-left">
                <div className="generalFormLabel-baseInfo">Kund</div>
                <div className="form-baseInfo-left">
                  <Form.Control
                    disabled
                    name="customer"
                    size="sm"
                    defaultValue={
                      customer.customerNumber && hasAccess
                        ? `${customer?.customerNumber} - ${customer?.name}`
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="infoForm-baseInfo-left">
                <div className="generalFormLabel-baseInfo">Kontakt</div>
                <div className="form-baseInfo-left">
                  <Autocomplete
                    className={
                      !isChecked
                        ? "newActivity-form-autocomplete"
                        : "newActivity-form-autocomplete-edit"
                    }
                    options={
                      customer === undefined
                        ? []
                        : (customer.relations.contacts as Contact[])
                    }
                    getOptionLabel={(option: contactOptions) => option.name}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    onChange={(e, val) => setDefaultContact(val)}
                    disabled={!isChecked}
                    value={
                      defaultContact
                        ? (defaultContact as Contact)
                        : emptyContact
                    }
                    disableClearable
                    popupIcon={
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="xs"
                        className="newActivity-dropdown-icon"
                      />
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.customerId === value?.customerId
                    }
                    onInputChange={handleContactSelect}
                  />
                </div>
              </div>

              <div className="infoContainer-baseinfo-container-left">
                <div className="infoContainer-baseInfo-left">
                  <Form.Control
                    className="form-baseInfo-left"
                    as="textarea"
                    rows={2}
                    disabled
                    defaultValue={
                      hasAccess
                        ? contact?.relations.contactProperties
                            .map((res) => `${res.name}: ${res.value}`)
                            .join("\n")
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="infoForm-baseInfo-left">
                <div className="generalFormLabel-baseInfo">Ansvarig</div>
                <div className="form-baseInfo-left">
                  <Autocomplete
                    className={
                      !isChecked
                        ? "newActivity-form-autocomplete"
                        : "newActivity-form-autocomplete-edit"
                    }
                    options={users as User[]}
                    getOptionLabel={(option: contactOptions) => option.name}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                    disabled={!isChecked}
                    onChange={(e, val) => setdefaultUser(val)}
                    value={defaultUser ? (defaultUser as User) : emptyUser}
                    disableClearable
                    popupIcon={
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="xs"
                        className="newActivity-dropdown-icon"
                      />
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    onInputChange={handleResponsibleSelect}
                  />
                </div>
              </div>

              <div className="infoForm-baseInfo-left">
                <div className="generalFormLabel-baseInfo">Prioritet</div>
                <Form.Select
                  className="form-baseInfo-left"
                  name="priority"
                  disabled={!isChecked}
                  onChange={(e) => handleEditinput(e)}
                  value={currentPriority}
                >
                  <option hidden value=""></option>
                  {priorityList.map((res, index) => {
                    return (
                      <option key={index} value={res}>
                        {res}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group
              className="formgroup-container-baseInfo-right"
              controlId={!isChecked ? "formBasicCustomer" : "formEditCustomer"}
            >
              <div className="infoForm-baseInfo-right">
                <div className="generalFormLabel-baseInfo">Rubrik</div>
                <div className="form-baseInfo-right">
                  <Form.Control
                    name="title"
                    className="form-baseInfo-right"
                    size="sm"
                    disabled={!isChecked}
                    defaultValue={hasAccess ? currentCase?.title : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEditinput(e)
                    }
                  />
                </div>
              </div>

              <div className="infoContainer-baseinfo-container-description">
                <div className="infoContainer-baseInfo-right">
                  <div className="generalFormLabel-baseInfo">Beskrivning</div>
                  <Form.Control
                    className="form-baseInfo-right"
                    name="description"
                    as="textarea"
                    rows={4}
                    disabled={!isChecked}
                    defaultValue={hasAccess ? currentCase?.description : ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEditinput(e)
                    }
                  />
                </div>
              </div>
              <div className="infoContainer-baseInfo-right-status">
                <div className="generalFormLabel-baseInfo">Intern status</div>
                <Form.Control
                  className="form-baseInfo-right"
                  as="textarea"
                  name="internalStatus"
                  disabled={!isChecked}
                  rows={2}
                  defaultValue={hasAccess ? currentCase?.internalStatus : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEditinput(e)
                  }
                />
              </div>
            </Form.Group>
          </Form>
        ) : (
          <NewCase
            newCase={newCase as Case}
            setNewCase={
              setNewCase as React.Dispatch<React.SetStateAction<Case>>
            }
            requiredContact={requiredContact}
            setRequiredContact={setRequiredContact}
          />
        )}
      </Card.Body>
    </Card>
  );
}
