import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { customerOption, Customer } from "../../Models/Customer/Customer";

interface searchCustomerProp {
  isChecked: boolean;
  allCustomers: Customer[];
  inputCustomer: Customer | null;
  setInputCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  handleInputChange: (
    e: React.SyntheticEvent,
    option: string,
    reason: string
  ) => void;
}

export const SearchCustomer = ({
  isChecked,
  allCustomers,
  setInputCustomer,
  inputCustomer,
  handleInputChange,
}: searchCustomerProp) => {
  return isChecked ? (
    <Tooltip title="Skicka eller ångra ändringar innan du byter kund">
      <Autocomplete
        className="searchContainer-customer-base"
        options={allCustomers.map((res) => res)}
        getOptionLabel={(option: customerOption) =>
          `${option.customerNumber} ${option.name}`
        }
        renderInput={(params) => <TextField label="Sök kund" {...params} />}
        size="small"
        disabled={isChecked}
        autoHighlight={true}
        onChange={(e, val) => setInputCustomer(val)}
        value={inputCustomer}
        onInputChange={handleInputChange}
      />
    </Tooltip>
  ) : (
    <Autocomplete
      className="searchContainer-customer-base"
      options={allCustomers.map((res) => res)}
      getOptionLabel={(option: customerOption) =>
        `${option.customerNumber} ${option.name}`
      }
      renderInput={(params) => <TextField label="Sök kund" {...params} />}
      size="small"
      disabled={isChecked}
      autoHighlight={true}
      onChange={(e, val) => setInputCustomer(val)}
      value={inputCustomer}
      onInputChange={handleInputChange}
    />
  );
};
