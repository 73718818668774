import { Relations } from "../Customer/Relations";

export const emptyContact: Contact = {
  name: "",
  customerId: "",
  id: "",
  isActive: false,
  relations: { contacts: [], contactProperties: [], cases: [], activities: [] },
};

export type Contact = {
  deleted?: Date;
  createdBy?: string;
  created?: Date;
  name: string;
  customerId: string;
  modified?: Date;
  modifiedBy?: string;
  id: string;
  isActive: boolean;
  relations: Relations;
  deletedBy?: string;
};
