import { Relations } from "../../Customer/Models/Customer/Relations";

export interface CaseOptions {
  caseNumber: string;
  title: string;
}

export const defaultCase = {
  salesOfferValue: 0,
  finishedBy: "",
  description: "",
  title: "",
  deletedBy: "",
  caseNumber: "",
  customerId: "",
  modifiedBy: "",
  id: "",
  salesSource: "",
  contactId: "",
  priceCeiling: 0,
  timeFrame: 0,
  internalStatus: "",
  salesDecisionPeriod: "",
  createdBy: "",
  priority: "",
  relations: {
    contacts: [],
    contactProperties: [],
    cases: [],
    activities: [],
  },
  salesProcess: "",
  responsibleUserId: "",
  salesProbability: 0,
  type: "",
};

export type Case = {
  salesOfferValue: number;
  finishedBy: string;
  finished?: Date;
  deadline?: Date;
  description: string;
  title: string;
  deletedBy: string;
  caseNumber: string;
  customerId: string;
  modifiedBy: string;
  id: string;
  salesSource: string;
  contactId: string;
  priceCeiling: number;
  created?: Date;
  timeFrame: number;
  internalStatus: string;
  salesDecisionPeriod: string;
  createdBy: string;
  priority: string;
  relations: Relations;
  salesProcess: string;
  responsibleUserId: string;
  salesProbability: number;
  type: string;
};
