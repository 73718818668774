import { Button, Card } from "react-bootstrap";
import "../CaseInformation.css";
import {
  faToggleOn,
  faToggleOff,
  faUpload,
  faFile,
  faFolderPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useState } from "react";

export function FileHandler() {
  const [showHidden, setShowHidden] = useState<boolean>(false);
  return (
    <Card
      className="cardContainer-fileHandler"
      style={{ backgroundColor: "#F8F5F3" }}
    >
      <div className="cardTitle-case-base sticky-top">
        <Card.Title className="cardContainerTitle">
          <div className="titleContainer-filehandler">
            <h2 className="ps-3 align-self-center">Filhantering</h2>
            {/* <div className="iconGroup-filehandler">
              {showHidden ? (
                <>
                  <Tooltip title="Åtgärder på markerade">
                    <FontAwesomeIcon
                      icon={faBars}
                      className="actions-icon-filehandler"
                    />
                  </Tooltip>
                  <Tooltip title="Dölj info">
                    <FontAwesomeIcon
                      icon={faToggleOff}
                      className="hide-icon-filehandler"
                      onClick={() => setShowHidden(!showHidden)}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Visa info">
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    className="show-icon-filehandler"
                    onClick={() => setShowHidden(!showHidden)}
                  />
                </Tooltip>
              )}

              <Tooltip title="Ny anteckning">
                <FontAwesomeIcon
                  icon={faFile}
                  className="file-icon-filehandler"
                />
              </Tooltip>
              <Tooltip title="Nytt dokument">
                <FontAwesomeIcon
                  icon={faFolderPlus}
                  className="doc-icon-filehandler"
                />
              </Tooltip>
              <Tooltip title="Ladda upp">
                <FontAwesomeIcon
                  icon={faUpload}
                  className="upload-icon-filehandler"
                />
              </Tooltip>
            </div> */}
          </div>
        </Card.Title>
      </div>
      <Card.Body className="infoBody-fileHandler text-center">
        <span>
          <div>
            <h2>Kommer snart</h2>
          </div>
        </span>
      </Card.Body>
    </Card>
  );
}
