import React, { useEffect, useRef, useState } from "react";
import "./CalendarStyles.css";
import "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import { Activity } from "../../../Errand/Models/Activity";
import {
  getStorage,
  setStorage,
} from "../../../../GlobalServices/SessionStorageService";
import { Case } from "../../../Errand/Models/Case";
import {
  createActivity,
  patchActivity,
} from "../../../Errand/Services/ActivityService";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { User } from "../../../Users/Models/User";
import { checkUser } from "../../../Users/Handlers/UserHandlers";
import { getCurrentUser } from "../../../Users/Services/UserServices";
import { useAlert } from "../../../Root/Handlers/Alerts/Alerts";

declare global {
  interface Window {
    scheduler: any;
  }
}

export interface SchedulerEvent {
  id?: string;
  start_date: Date | string;
  end_date: Date | string;
  text: string;
  description?: string;
  caseId?: string;
  case?: string;
  resourceType?: string;
  category?: string;
  confirmed?: boolean;
  status?: string;
  resourceTime?: number;
  full_day: boolean;
  isAwaitingFeedback: boolean;
  isCustomerAgreement: boolean;
  isLocked: boolean;
  userId?: string;
  isBegun: boolean;
  isFinished: boolean;
}

interface LightBoxSection {
  name: string;
  height?: number;
  map_to: string;
  type: string;
  focus?: boolean;
  checked_value?: boolean;
  unchecked_value?: boolean;
  options?: { key: string | number; label: string }[];
}

// kategori interface
interface CategoryOption {
  key: string;
  label: string;
  css: string;
}

export const resourceOptions = [
  { key: "1", label: "Övrigt" },
  { key: "2", label: "Avtal" },
  { key: "3", label: "Debiterbar" },
  { key: "4", label: "Debiterbar preliminär" },
  { key: "5", label: "Ledig" },
  { key: "6", label: "Säljtid" },
  { key: "7", label: "Support" },
];

export const categoryOptions: CategoryOption[] = [
  { key: "1", label: "Möte", css: "category-meeting" },
  { key: "2", label: "Konferans", css: "category-conference" },
  { key: "3", label: "Webinarie", css: "category-webinar" },
  { key: "4", label: "Workshop", css: "category-workshop" },
  { key: "5", label: "Internt arbete", css: "category-internal-work" },
  { key: "6", label: "Privat", css: "private-plans" },
];

const scheduler = window.scheduler;

export function ProfileCalendar() {
  const schedulerContainer = useRef<HTMLDivElement>(null);
  const [events, setEvents] = useState<SchedulerEvent[]>([]);
  const [allCases, setAllCases] = useState<Case[]>([]);
  const [user, setUser] = useState<User>();

  const SpecificationCheckbox = [
    { label: "Påbörjad" },
    { label: "Färdig" },
    { label: "Väntar på svar" },
    { label: "Överenskommelse med kund" },
    { label: "Låst" },
  ];

  useEffect(() => {
    checkUser(user, setUser as React.Dispatch<React.SetStateAction<User>>);
    const getUserData = async () => {
      const data = await getCurrentUser();
      setUser(data);
    };
    getUserData();
  }, []);

  useEffect(() => {
    user?.relations?.activities &&
      user?.relations?.activities.map((res) => {
        setEvents((prev) => [
          ...prev,
          {
            id: res.id,
            start_date: res.start,
            end_date: res.end,
            text: res.title,
            description: res.description,
            caseId: res.caseId,
            case: getCaseName(res.caseId as string),
            resourceType: getTypeKey(res.resourceType as string),
            resourceTime: res.resourceTime,
            full_day: res.isAllDay,
            isAwaitingFeedback: res.isAwaitingFeedback,
            isCustomerAgreement: res.isCustomerAgreement,
            isLocked: res.isLocked,
            category: getCategoryColor(res.category as string),
            isBegun: res.isBegun,
            isFinished: res.isFinished,
          },
        ]);
      });
  }, [user]);

  useEffect(() => {
    if (!allCases.length && getStorage("cases")) {
      setAllCases(getStorage("cases"));
    } else if (allCases.length && !getStorage("cases")) {
      setStorage("cases", allCases);
    } else if (!allCases.length && !getStorage("cases")) {
      if (getStorage("customer")) {
        setAllCases([]);
      }
    }
  }, [allCases]);

  const getCaseName = (id: string) => {
    const obj = allCases.find((res) => res.id === id);
    return obj?.title;
  };

  const getTypeKey = (input: string) => {
    const data = resourceOptions.find((res) => res.label === input);
    return data?.key;
  };

  const getTypelabel = (input: string) => {
    const data = resourceOptions.find((res) => res.key === input);
    return data?.label as string;
  };

  const getCategoryLabel = (input: string) => {
    const data = categoryOptions.find((res) => res.key.toString() === input);
    return data?.label;
  };

  const getCategoryColor = (category: string) => {
    const data = categoryOptions.find((res) => res.label === category);
    return data?.key;
  };

  const getUserId = () => {
    if (!user?.id && getStorage("user")) {
      const storageUser = getStorage("user") as User;
      return storageUser.id;
    } else if (user?.id !== undefined) {
      return user.id;
    }
  };

  useEffect(() => {
    scheduler.plugins({
      agenda_view: true,
      editors: true,
      multiselect: true,
    });

    scheduler.i18n.setLocale("sv");
    scheduler.locale.labels.agenda_tab = "TODO";
    scheduler.config.time_step = 15;
    scheduler.config.readonly = false;
    scheduler.config.drag_move = true;
    scheduler.config.hour_size_px = 60;
    scheduler.config.agenda_start = null;
    scheduler.config.agenda_end = null;
    scheduler.config.scroll_hour = 12 - 6;

    const initSchedulerEvents = () => {
      if (scheduler._$initialized) {
        return;
      }

      //todo titel
      scheduler.templates.agenda_text = (
        start: Date,
        end: Date,
        event: SchedulerEvent
      ) => {
        let iconsHtml = "";

        if (event.isLocked) {
          iconsHtml += "<i class='fas fa-lock lockedForOthers'></i> ";
        }

        if (event.isAwaitingFeedback) {
          iconsHtml += "<i class='fas fa-clock waitingResponse'></i> ";
        }

        if (event.isCustomerAgreement) {
          iconsHtml += "<i class='fas fa-thumbs-up agreedWithCustomer'></i> ";
        }

        var descriptionText = event.description
          ? `<br/><span class='event-description'>${event.description}</span>`
          : "";

        return `<div class='agenda-event-title'><strong>${event.text}</strong> <span class='event-icons'>${iconsHtml}</span></div>${descriptionText}`;
      };
      //titel
      scheduler.templates.event_text = (
        start: Date,
        end: Date,
        event: SchedulerEvent
      ) => {
        let iconsHtml = "";

        if (event.isLocked) {
          iconsHtml += "<i class='fas fa-lock lockedForOthers'></i>";
        }

        if (event.isAwaitingFeedback) {
          iconsHtml += "<i class='fas fa-clock waitingResponse'></i>";
        }

        if (event.isCustomerAgreement) {
          iconsHtml += "<i class='fas fa-thumbs-up agreedWithCustomer'></i>";
        }

        return `<div class='event-content'><div class='event-text'><b>${event.text}</b></div><div class='event-icons'>${iconsHtml}</div></div>`;
      };

      scheduler.form_blocks["resource_time"] = {
        render: () => {
          return "<div><input type='number' step=0.5></input></div>";
        },
        set_value: (node: HTMLElement, value: string) => {
          (node.querySelector("input") as HTMLInputElement).value = value || "";
        },
        get_value: (node: HTMLElement, value: string) => {
          return (node.querySelector("input") as HTMLInputElement)?.value || "";
        },
        focus: (node: HTMLElement) => {
          (node.querySelector("input") as HTMLInputElement)?.focus();
        },
      };

      scheduler.serverList("specification_checkbox", SpecificationCheckbox);

      scheduler.config.lightbox.sections = [
        {
          name: "Titel",
          height: 50,
          map_to: "text",
          type: "textarea",
          focus: true,
        },
        {
          name: "Beskrivning",
          height: 130,
          map_to: "description",
          type: "textarea",
        },

        /*Ändra typ till custom, testa typ Autocomplete eller dylikt, ska visa alla ärende kopplat till användaren*/
        {
          name: "Ärende",
          height: 23,
          map_to: "case",
          type: "textarea",
        },

        {
          name: "Kategori",
          height: 23,
          map_to: "category",
          type: "select",
          options: categoryOptions.map((option) => ({
            key: option.key,
            label: option.label,
          })),
        },

        {
          name: "Resurstyp",
          height: 23,
          map_to: "resourceType",
          type: "select",
          options: resourceOptions.map((option) => ({
            label: option.label,
            key: option.key,
          })),
        },
        {
          name: "",
          height: 23,
          map_to: "resourceTime",
          type: "resource_time",
        },
        {
          name: "Heldag",
          map_to: "full_day",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },

        {
          name: "Tid",
          height: 72,
          type: "time",
          map_to: "auto",
          time_format: ["%Y", "%m", "%d", "%H:%i"],
          id: "timeField",
        },
        {
          name: "Påbörjad",
          map_to: "isBegun",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },
        {
          name: "Färdig",
          map_to: "isFinished",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },
        {
          name: "Väntar på svar",
          map_to: "isAwaitingFeedback",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },
        {
          name: "Överenskommelse med kund",
          map_to: "isCustomerAgreement",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },
        {
          name: "Låst",
          map_to: "isLocked",
          type: "checkbox",
          checked_value: true,
          unchecked_value: false,
        },
      ] as LightBoxSection[];

      const updateTimeSection = (fullDay: boolean) => {
        const timeSection = scheduler.formSection("Tid");
        const timeNode = timeSection.node;
        timeNode.style.display = fullDay === true ? "none" : "";

        const children = timeNode.children;

        for (const child of children) {
          if (child.ariaLabel === "Hour Minute" && fullDay) {
            child.disabled = true;
          }
        }
      };

      scheduler.attachEvent("onLightbox", (id: string): void => {
        const event = scheduler.getEvent(id) as SchedulerEvent;
        updateTimeSection(event.full_day);
      });

      scheduler.attachEvent(
        "onEventChanged",
        (id: string, e: SchedulerEvent): void => {
          if (e.full_day) {
            (e.start_date as Date).setHours(0, 0, 0, 0);
            e.end_date = new Date((e.end_date as Date).setHours(0, 0, 0, 0));
          }

          const category = e.category?.toString() as string;

          const updateActivity = async (e: SchedulerEvent) => {
            const eventValues: Activity = {
              title: e.text,
              description: e.description,
              start: e.start_date,
              end: e.end_date,
              category: getCategoryLabel(category),
              resourceType: getTypelabel(e.resourceType as string),
              resourceTime: e.resourceTime as number,
              caseId: e.caseId,
              isAllDay: e.full_day,
              isBegun: e.isBegun,
              isFinished: e.isFinished,
              isAwaitingFeedback: e.isAwaitingFeedback,
              isCustomerAgreement: e.isCustomerAgreement,
              isLocked: e.isLocked,
              userId: user?.id ? user?.id : getUserId(),
            };
            if (!e.caseId) {
              delete eventValues.caseId;
            }
            await patchActivity(eventValues, id);
          };
          updateActivity(e);
          scheduler.updateEvent(id);
        }
      );

      scheduler.attachEvent(
        "onEventAdded",
        (id: string, e: SchedulerEvent): void => {
          const checkIfFullDay = () => {
            if (e.full_day) {
              (e.start_date as Date).setHours(0, 0, 0, 0);
              e.end_date = new Date((e.end_date as Date).setHours(0, 0, 0, 0));
            }
            if (e.resourceTime === null || undefined) {
              const timestamp =
                (e.end_date as Date).valueOf() -
                (e.start_date as Date).valueOf();
              const diff = timestamp / 1000 / 60 / 60;
              e.resourceTime = Math.round(diff * 10) / 10;
            }
          };

          const category = e.category?.toString() as string;

          const postActivity = async (e: SchedulerEvent) => {
            const eventValues: Activity = {
              title: e.text,
              description: e.description,
              start: e.start_date,
              end: e.end_date,
              caseId: e.caseId,
              category: getCategoryLabel(category),
              resourceType: getTypelabel(e.resourceType as string),
              resourceTime: e.resourceTime as number,
              isAllDay: e.full_day,
              isBegun: e.isBegun,
              isFinished: e.isFinished,
              isAwaitingFeedback: e.isAwaitingFeedback,
              isCustomerAgreement: e.isCustomerAgreement,
              isLocked: e.isLocked,
              userId: user?.id ? user?.id : getUserId(),
            };
            await createActivity(eventValues);
          };
          checkIfFullDay();
          postActivity(e);
        }
      );

      scheduler.config.hour_date = "%H:%i";

      scheduler.templates.hour_scale = scheduler.date.date_to_str(
        scheduler.config.hour_date
      );

      scheduler._$initialized = true;
    };

    scheduler.templates.event_class = (
      start: Date,
      end: Date,
      event: SchedulerEvent
    ) => {
      const category = categoryOptions.find(
        (option) => option.key === event.category
      );
      return category ? category.css : "";
    };

    //förseningscheck
    scheduler.templates.event_class = (start: Date, end: Date, event: any) => {
      const categoryCss =
        categoryOptions.find(
          (option) => option.key.toString() === event.category
        )?.css || "";

      // Använder 'finished' för att avgöra om aktiviteten är markerad som färdig
      const isFinished = event.isFinished;

      const endDate = new Date(end);
      const currentDate = new Date();
      endDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const isLate = endDate < currentDate && !isFinished;

      const finishedClass = isFinished ? "event-finished" : "";

      return `${categoryCss} ${isLate ? "event-late" : ""} ${finishedClass}`;
    };

    // Agenda om den är klar så ska den inte visas
    scheduler.filter_agenda = function (id: any, event: any) {
      return !event.isFinished;
    };

    if (schedulerContainer.current) {
      scheduler.skin = "material";
      scheduler.config.header = [
        "day",
        "week",
        "month",
        "agenda",
        "date",
        "prev",
        "today",
        "next",
      ];
      scheduler.xy.scale_width = 70;
      scheduler.init(schedulerContainer.current, new Date(), "month");
      scheduler.clearAll();
      scheduler.init(schedulerContainer.current, new Date(), "week");

      initSchedulerEvents();

      scheduler.clearAll();
      scheduler.parse(events);
    }

    return () => {
      scheduler.detachEvent("onEventAdded");
      scheduler.detachEvent("onEventChanged");
      scheduler.detachEvent("onEventDeleted");
      scheduler.clearAll();
    };
  }, [events]);

  return (
    <div className="contentBodyCalendar">
      <div className="calendarBody">
        <div className="calendar-page">
          <div ref={schedulerContainer} className="scheduler-container"></div>
        </div>
      </div>
    </div>
  );
}
